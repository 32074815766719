.edit-creditcard-container {
    width: 100%;
    height: 100%;
    position: relative;

    >body {
        display: flex;
        flex-direction: column;
        gap: 2%;
        width: 95%;
        margin-left: 2.5%;
        height: 86%;
        overflow-y: auto;
        padding: 2% 0;
    }

    >footer {
        height: 10%;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: auto;

        button {
            font-family: Jost;
            border: none;
            background-color: #65C37A;
            font-size: 1.6vw;
            font-weight: 600;
            width: 90%;
            height: 2.5vw;
            color: #fafafa;
            border-radius: 0.8vw;
            cursor: pointer;

            &:hover {
                background-color: #4BB763;
                transition: background-color 0.3s ease;
            }
        }
    }
}

.creditcard-rendered-in-edit-view {
    width: 90%;
    margin-left: 2.5%;
    font-family: Jost;
    border-radius: 1vw;
    box-shadow: 0 0 0.2vw 0.05vw rgba(0, 0, 0, 0.25);
    padding: 0 2.5%;
    transition: min-height 0.2s ease, background-color 0.3s ease;


    >div {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 2.75%;

        /*img e info*/
        >div {
            width: 70%;
            height: 100%;
            display: flex;
            align-items: center;

            >img {
                width: 2.5vw;
                height: 2.5vw;
                border-radius: 100%;
            }

            /*info*/
            >div {
                margin-left: 2%;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;

                >p:nth-of-type(1) {
                    font-size: 1.1vw;
                    color: #242424;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    width: 100%;
                }

                >p:nth-of-type(2) {
                    font-size: 0.9vw;
                    color: #848484;
                }

                >p:nth-of-type(3) {
                    font-size: 0.9vw;
                    color: #242424;

                    >b {
                        font-weight: 500;
                    }
                }
            }
        }

        >p {
            font-size: 1.2vw;
            font-weight: 500;
            width: 30%;
            text-align: right;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}

.creditcard-rendered-in-edit-selected {
    height: 5.7vw;
    width: 100%;
    display: flex;
    flex-direction: column;
    animation: fadeIn 0.3s forwards;
    padding-bottom: 0.5vw;

    >body {
        height: 50%;
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-direction: column;

        >div:nth-of-type(1) {
            display: flex;
            align-items: center;

            >label {
                font-family: Jost;
                font-size: 1vw;
                width: 22%;
            }

            >input {
                margin-left: 2%;
                background-color: #fff;
                border-radius: 0.2vw;
                height: 100%;
                border: solid 0.1vw #5A5A5A;
                font-family: Jost;
                font-size: 1vw;
                color: #575757;
                text-indent: 2%;
                width: 75.7%;

                &:focus {
                    outline: none;
                }
            }
        }

        >div:nth-of-type(2) {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            margin-top: 2%;

            >div:nth-of-type(1),
            div:nth-of-type(2) {
                display: flex;
                justify-content: space-between;
                white-space: nowrap;
                width: 36%;

                >label {
                    font-family: Jost;
                    font-size: 1vw;
                }



                >input {
                    margin-left: 2%;
                    background-color: #fff;
                    border-radius: 0.3vw;
                    height: 100%;
                    border: solid 0.1vw #5A5A5A;
                    font-family: Jost;
                    font-size: 1vw;
                    color: #575757;
                    text-indent: 12%;
                    width: 4vw;

                    &:focus {
                        outline: none;
                    }
                }
            }
        }
    }

    >footer {
        height: 20%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        /*Confirmar alteração*/
        >p:nth-of-type(1) {
            font-size: 0.9vw;
            color: #65C37A;
            cursor: pointer;

            &:hover {
                text-decoration: underline;
            }
        }

        /*Deletar*/
        >p:nth-of-type(2) {
            font-size: 0.8vw;
            color: #242424;
            cursor: pointer;

            &:hover {
                color: #ED4C5C;
                text-decoration: underline;
            }
        }
    }
}




.create-new-credit-card-container {
    position: absolute;
    z-index: 7;
    background-color: #fafafa;
    height: 90%;
    width: 100%;
    bottom: 0;
    border-radius: 1vw 1vw 0 0;
    box-shadow: 0 0 0.2vw 0.05vw rgba(0, 0, 0, 0.25);
    animation: fadeIn 0.3s forwards;

    /*selecionar banco*/
    >header {
        height: 10%;
        width: 95%;
        margin-left: 2.5%;
        border-bottom: 0.1vw solid #D1D1D1;

        >p {
            font-family: Jost;
            font-size: 1.4vw;
            margin-top: 2.5%;
        }
    }

    >div {
        width: 95%;
        margin-left: 2.5%;
        margin-top: 2.5%;
        height: 55%;
    }

    >footer {
        height: 27.5%;
        width: 95%;
        margin-left: 2.5%;
        border-top: 0.1vw solid #D1D1D1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        label {
            font-family: Jost;
            font-size: 1.3vw;
        }

        input {
            background-color: #fff;
            border-radius: 0.2vw;
            height: 1.5vw;
            border: solid 0.1vw #5A5A5A;
            font-family: Jost;
            font-size: 1.1vw;
            color: #575757;
            text-indent: 2%;
            margin-left: 2.5%;

            &:focus {
                outline: none;
            }
        }

        /*inputs*/
        >div:nth-of-type(1) {
            height: 50%;
            margin-top: 1.5%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            /*inputs - Nome do cartão*/
            >div:nth-of-type(1) {
                display: flex;
                align-items: center;

                label {
                    white-space: nowrap;
                    width: 24%;
                }


                input {
                    width: 76%;
                }

            }

            /*inputs - Dias*/
            >div:nth-of-type(2) {
                display: flex;
                align-items: center;
                justify-content: space-between;

                /*dia fechamento*/
                >div:nth-of-type(1) {
                    justify-content: left;
                }

                /*dia vencimento*/
                >div:nth-of-type(2) {
                    justify-content: right;
                }

                >div {
                    display: flex;
                    align-items: center;
                    width: 40%;

                    label {
                        font-size: 1vw;
                        white-space: nowrap;
                    }

                    input {
                        width: 4vw;
                        height: 1.5vw;
                        text-indent: 10%;
                    }
                }
            }
        }

        /*buttons - create and cancel*/
        >div:nth-of-type(2) {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 20%;

            p {
                font-size: 1vw;
                font-family: Jost;
                color: #242424;
                cursor: pointer;

                &:hover {
                    color: #ED4C5C;
                    text-decoration: underline;
                }
            }

            button {
                font-family: Jost;
                border: none;
                background-color: #65C37A;
                font-size: 1.2vw;
                font-weight: 600;
                width: 30%;
                height: 2vw;
                color: #fafafa;
                border-radius: 0.4vw;
                cursor: pointer;

                &:hover {
                    background-color: #4BB763;
                    transition: background-color 0.3s ease;
                }
            }
        }
    }

}


.creating-new-credit-card-overlay {
    position: absolute;
    top: -11.2%;
    height: 100%;
    width: 100%;
    z-index: 6;
    animation: fadeIn 0.3s forwards;
    background-color: rgb(0, 0, 0, 0.4);
}

@media (max-width: 768px) {
    .edit-creditcard-container {

        >footer {
            button {
                background-color: #65C37A;
                font-size: 6vw;
                width: 90%;
                height: 12vw;
                border-radius: 2vw;
            }
        }
    }

    .creditcard-rendered-in-edit-view {
        border-radius: 3vw;
        box-shadow: 0 0 0.8vw 0.2vw rgba(0, 0, 0, 0.25);

        >div {
            margin-top: 3.5%;

            /*img e info*/
            >div {
                width: 70%;
                height: 100%;
                display: flex;
                align-items: center;

                >img {
                    width: 12vw;
                    height: 12vw;
                    border-radius: 50%;
                }

                /*info*/
                >div {

                    >p:nth-of-type(1) {
                        font-size: 4vw;
                        margin-left: 3%;
                        max-width: 45vw;
                        width: 45vw;
                    }

                    >p:nth-of-type(2) {
                        font-size: 3vw;
                        margin-left: 3%;
                    }

                    >p:nth-of-type(3) {
                        font-size: 3vw;
                        margin-left: 3%;
                    }
                }
            }

            >p {
                width: 35%;
                font-size: 4vw;
                font-weight: 500;
            }
        }
    }

    .creditcard-rendered-in-edit-selected {
        height: 60%;

        >body {
            height: 80%;

            >div:nth-of-type(1) {
                flex-direction: column;
                align-items: start;

                >label {
                    font-family: Jost;
                    font-size: 4vw;
                    width: 100%;
                }

                >input {
                    margin-left: 0%;
                    border-radius: 1vw;
                    height: 5vw;
                    font-size: 4.3vw;
                    width: 100%;
                }
            }

            >div:nth-of-type(2) {

                justify-content: space-between;
                width: 100%;
                margin-top: 2%;

                >div:nth-of-type(1),
                div:nth-of-type(2) {
                    display: flex;
                    justify-content: space-between;
                    flex-direction: column;
                    align-items: start;
                    white-space: nowrap;
                    width: 40%;

                    >label {
                        font-family: Jost;
                        font-size: 4vw;
                    }

                    >input {
                        margin-left: 0%;
                        border-radius: 1vw;
                        height: 5vw;
                        font-size: 4.3vw;
                        width: 100%;
                        text-align: center;
                        text-indent: 0;
                    }
                }
            }
        }

        >footer {
            margin-top: 3%;

            /*Confirmar alteração*/
            >p:nth-of-type(1) {
                font-size: 4vw;
            }

            /*Deletar*/
            >p:nth-of-type(2) {
                font-size: 4vw;
            }
        }
    }




    .create-new-credit-card-container {

        /*selecionar banco*/
        >header {
            display: flex;
            align-items: center;

            >p {
                font-size: 5.5vw;
                font-weight: 500;
            }
        }

        >footer {

            label {
                font-size: 5vw;
            }

            input {
                border-radius: 1vw;
                height: 6vw;
                font-size: 4.3vw;
            }

            /*inputs*/
            >div:nth-of-type(1) {

                /*inputs - Nome do cartão*/
                >div:nth-of-type(1) {

                    label {
                        white-space: nowrap;
                        width: 45%;
                    }


                    input {
                        width: 55%;
                    }

                }

                /*inputs - Dias*/
                >div:nth-of-type(2) {

                    /*dia fechamento*/
                    >div:nth-of-type(1) {
                        justify-content: left;
                    }

                    /*dia vencimento*/
                    >div:nth-of-type(2) {
                        justify-content: right;
                    }

                    >div {
                        display: flex;
                        align-items: center;
                        width: 40%;

                        label {
                            font-size: 4vw;
                        }

                        input {
                            width: 12vw;
                            border-radius: 1vw;
                            height: 6vw;
                            font-size: 4.3vw;
                        }
                    }
                }
            }

            /*buttons - create and cancel*/
            >div:nth-of-type(2) {

                p {
                    font-size: 4vw;
                }

                button {
                    font-size: 5vw;
                    font-weight: 600;
                    width: 40%;
                    margin-right: 2%;
                    height: 8vw;
                    border-radius: 2vw;
                    cursor: pointer;

                    &:hover {
                        background-color: #4BB763;
                        transition: background-color 0.3s ease;
                    }
                }
            }
        }

    }
}