.MainContentMessageView {
    height: 100%;
    margin-top: 5%;
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: end;
    gap: 5%;
    position: relative;

    >header {
        width: 50%;
        text-align: center;
        font-family: Jost;
        display: flex;
        flex-direction: column;
        align-items: end;

        >h1 {
            font-weight: 600;
            color: #EEC842;
            font-size: 2.3vw;
        }

        >h2 {
            width: 90%;
            margin-right: 5%;
            color: #242424;
            font-size: 0.95vw;
            font-weight: 500;
        }
    }

    >body {
        background-color: #fafafa;
        width: 50%;
        height: 80%;
        border-radius: 2vw;
        box-shadow: 0px 0vw 0.3vw 0.02vw rgba(0, 0, 0, 0.25);
        font-family: Jost;
        display: flex;
        flex-direction: column;
        align-items: center;

        >div:nth-of-type(1) {
            /*Option buttons*/
            width: 80%;
            height: 10%;
            margin-top: 5%;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        >div:nth-of-type(2) {
            /*Option buttons*/
            width: 80%;
            height: 10%;
            display: flex;
            align-items: end;
            justify-content: space-between;
            font-size: 1vw;

            >button {
                margin-top: 3%;
                width: 45%;
                height: 80%;
                font-family: Jost;
                border: none;
                background-color: #EEC842;
                border-radius: 2vw;
                color: #fafafa;
                font-size: 1.1vw;
                font-weight: 500;
                cursor: pointer;
                transition: background-color 0.3s ease;

                &:hover {
                    background-color: #C7A630;
                }
            }
        }

    }


    textarea {
        margin-top: 3%;
        max-width: 78%;
        border-radius: 0.9vw;
        height: 60%;
        resize: none;
        padding: 1%;
        outline: none;
        border: solid 0.1vw #575757;
        font-family: Jost;
        font-size: 1vw;
    }

    >img {
        position: absolute;
        bottom: 5%;
        left: 0;
        width: 32.5vw;
    }


}

.buttonFormsSendMessage {
    width: 45%;
    height: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2vw;
    cursor: pointer;
    border: solid 0.15vw #EEC842;

    >p {
        margin-right: 3%;
        font-size: 1.1vw;
        color: #EEC842;
        font-weight: 500;

    }

    &:hover {
        background-color: #EEC842;
        border: none;
        transition: color 0.3s ease, background-color 0.3s ease, border 0.3s ease;

        >p {
            color: #fafafa;
        }
    }
}

.buttonSelectedSendMessage {
    background-color: #EEC842;
    border: none;

    >p {
        font-weight: 500;
        color: #fafafa;
    }

}

#TalkInEmailIfNecessary {
    font-size: 0.75vw;
    margin-top: 0;
}

@media (max-width: 768px) {

    .MainContentMessageView {
        height: 85%;
        margin-top: 5%;
        width: 100%;
        gap: 5%;
        position: relative;
        align-items: center;

        >header {
            width: 90%;
            height: 30vw;
            align-items: center;

            >h1 {
                font-weight: 600;
                color: #EEC842;
                font-size: 7vw;
            }

            >h2 {
                width: 95%;
                margin-right: 0%;
                font-size: 4vw;
                text-align: justify;
            }
        }

        >body {
            width: 90%;
            height: 72%;
            box-shadow: 0px 0vw 1vw 0.5vw rgba(0, 0, 0, 0.25);
            margin-top: 5%;
            justify-content: space-between;


            >div:nth-of-type(1) {
                /*Option buttons*/
                width: 90%;
                height: 7.5%;
                margin-top: 5%;
            }

            >div:nth-of-type(2) {
                /*submit buttons*/
                width: 88%;
                height: 10%;
                display: flex;
                align-items: end;
                justify-content: space-between;
                margin-bottom: 5%;


                >p {
                    width: 35%;
                    font-size: 4vw;

                }

                >button {
                    margin-top: 3%;
                    width: 65%;
                    height: 80%;
                    background-color: #EEC842;
                    border-radius: 2vw;
                    font-size: 4vw;
                    font-weight: 500;
                    transition: background-color 0.3s ease;

                    &:hover {
                        background-color: #EEC842;
                    }
                }
            }

        }


        textarea {
            margin-top: 0%;
            width: 86%;
            max-width: 100%;
            border-radius: 1vw;
            height: 40%;
            resize: none;
            padding: 1%;
            outline: none;
            border: solid 0.1vw #575757;
            font-family: Jost;
            font-size: 4.5vw;
        }

        >img {
            display: none;
        }


    }

    .buttonFormsSendMessage {
        width: 45%;
        height: 80%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 2vw;
        cursor: pointer;
        border: solid 0.15vw #EEC842;

        >p {
            margin-right: 3%;
            font-size: 3.5vw;
            color: #EEC842;
            font-weight: 500;

        }

        &:hover {
            background-color: #EEC842;
            border: none;
            transition: color 0.3s ease, background-color 0.3s ease, border 0.3s ease;

            >p {
                color: #fafafa;
            }
        }
    }

    .buttonSelectedSendMessage {
        background-color: #EEC842;
        border: none;

        >p {
            font-weight: 500;
            color: #fafafa;
        }

    }

    #TalkInEmailIfNecessary {
        font-size: 2.5vw;
        text-align: center;
        width: 85%;
        margin-top: 0;
    }

}