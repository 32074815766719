@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}


.importFinancesMainContainer {
    position: absolute;
    background-color: #fafafa;
    width: 72.5%;
    height: 100%;
    animation: fadeIn 0.4s forwards;
    border-radius: 0.25vw 0 0 0.25vw;
    z-index: 2;

    >header {
        width: 95%;
        margin-left: 2.5%;
        height: 10%;
        display: flex;
        align-items: end;
        justify-content: space-between;
        padding-bottom: 3%;
        border-bottom: solid 0.1vw rgba(34, 34, 34, 0.4);

        >p:nth-of-type(1) {
            font-family: Jost;
            width: fit-content;
            font-size: 2vw;
            font-weight: 500;
        }

        >p:nth-of-type(2) {
            font-family: Jost;
            width: fit-content;
            font-size: 1.2vw;
            color: #575757;

            &:hover {
                text-decoration: underline;
                cursor: pointer;
            }
        }
    }

    >body {
        display: flex;
        justify-content: space-between;
        height: 60%;
        width: 95%;
        margin-left: 2.5%;
        gap: 5%;

        >section:nth-of-type(1) {
            /*Lista passo a passo*/
            width: 47.5%;
            font-family: Jost;
            margin-top: 5%;
            height: 90%;
            max-height: 25vw;
            overflow-x: auto;

            >header {
                font-size: 1.5vw;
                font-weight: 500;
            }

            >ol {
                /*Lista ordenada*/
                margin-left: 5%;
                text-align: justify;
                font-size: 1.05vw;



                >li {
                    margin-top: 3%;

                    >a {
                        color: #1714b9;
                    }
                }

            }
        }

        >section:nth-of-type(2) {
            /*Container carrossel*/
            margin-top: 5%;
            width: 47.5%;
            height: 90%;

            header {
                font-family: Jost;
                display: flex;
                flex-direction: column;
                align-items: center;

                >p {
                    font-size: 1.5vw;
                    font-weight: 500;
                    width: 100%;
                    text-align: center;
                }
            }

            >section {
                width: 90%;
                margin-left: 5%;
                height: 70%;
                margin-top: 5%;
                box-shadow: 0.0vw 0.05vw 0.2vw 0.15vw rgba(0, 0, 0, 0.25);
                border-radius: 0.4vw;

                >figure {
                    width: 100%;
                    height: 100%;
                    overflow: hidden;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    img {
                        width: 100%;
                        height: 90%;
                    }
                }

                >footer {
                    width: 100%;
                    height: 10%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 1.5%;

                    >div {
                        border-radius: 50%;
                        width: 0.65vw;
                        height: 0.65vw;
                        background-color: rgba(34, 34, 34, 0.5);
                        cursor: pointer;
                        transition: background-color 0.3s ease;
                    }

                    .clickedOptionExampleImage {
                        transition: background-color 0.3s ease;
                        background-color: #EEC842;
                    }
                }
            }
        }
    }



    >footer {
        /*Footer de toda a aba de import - botão de importar e confirmar*/
        height: 25%;
        width: 95%;
        display: flex;
        align-items: end;
        justify-content: space-between;

        >div:nth-of-type(1) {
            width: 50%;
            height: 80%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-evenly;
            border: 2px dashed #ccc;
            border-radius: 0.2vw;
            cursor: pointer;

            img{
                width: 2vw;
            }

            >p:nth-of-type(1){
                font-size: 1.1vw;
            }

            >p:nth-of-type(3){
                font-size: 0.9vw;
                color: #575757;
            }

            >p {
                font-family: Jost;
                font-size: 1vw;
                white-space: nowrap;
                text-align: center;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            
            >button{
                border: none;
                border-radius: 0.3vw;
                height: 2vw;
                width: 60%;
                font-size: 1.2vw;
                color: #EEC842;
                border: #EEC842 solid 0.1vw;
                background-color: transparent;

                &:hover{
                    background-color: #EEC842;
                    color: #fafafa;
                    cursor: pointer;
                    transition: background-color 0.3s ease;

                }
            }

            &:hover{
                background-color: #e5e5e5;
                transition: background-color 0.3s ease;
            }
        }

        >button:nth-of-type(1) {
            width: 19%;
            height: 30%;
            font-family: Jost;
            font-size: 1.5vw;
            font-weight: 500;
            color: #fafafa;
            border: none;
            border-radius: 0.5vw;
            background-color: #EEC842;
            cursor: pointer;
            box-shadow: 0.0vw 0.0vw 0.1vw 0.1vw rgba(0, 0, 0, 0.25);
            transition: background-color 0.3s ease;

            &:hover {
                background-color: #C7A630;
                transition: background-color 0.3s ease;
            }
        }

    }
}

#importSuccessful{
    color: #489946;
    font-size: 1vw;
    white-space: nowrap;   
    overflow: visible;
    margin-bottom: 2%;
    margin-left: 5%;
    width: 20%;
    font-family: Jost;
}