.SmallFinancialCardLine{
    height: 1.5vw;
    width: 90%;
    margin-left: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1%;
    border-bottom: solid 0.1vw #D1D1D1;
}

.ReducedCardLineCenterDiv{
    width: 100%;
}

.ReducedCardTextNameData{
    color: #000;
    text-align: center;
    font-family: Jost;
    font-size: 0.9vw;
    font-style: normal;
    font-weight: 300;
    line-height: normal; 
}

.dataValueReducedCard{
    white-space: nowrap;
    text-align: left;
    width: 5vw;
    max-width: 6vw;
}

.nameValueReducedCard{
    white-space: nowrap;          
    overflow: hidden;             
    text-overflow: ellipsis; 
    text-align: left;
    width: 16vw;
    max-width: 16vw;

}

.ReducedCardValue{
    white-space: nowrap;          
    text-align: center;
    font-family: Jost;
    font-size: 0.9vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal; 
    text-align: right;
    width: 7vw;
    max-width: 7vw;
}

#income{
    color: #489946;
}

#spend{
    color: #FF6675; 
}

@media (min-width: 1025px) and (max-width: 1366px) {}

@media only screen and (max-width: 1000px) {}

@media only screen and (max-width: 768px) {
    .SmallFinancialCardLine{
        height: 8.5vw;
        margin-left: 5%;
        justify-content: space-around;
        position: relative;
        border: none;
    }
    .ReducedCardTextNameData{
        font-size: 4vw;
        font-weight: 500;
        width: 70%;
    }

    .dataValueReducedCard{
        position: absolute;
        white-space: nowrap;
        text-align: left;
        left: 2vw;
        top: 6.4vw;
        font-size: 2.8vw;
        font-weight: 400;
        color: #979797;
    }
    .nameValueReducedCard{
        width: 50vw;
        max-width: 50vw;
    }
    .ReducedCardValue{
        font-size: 3.7vw;
        font-weight: 500;
        text-align: left;
        text-align: right;
        width: 27vw;
        max-width: 27vw;
    }
}