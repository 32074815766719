.main-categories-container {

    header {
        display: flex;
        align-items: center;
        height: 20%;
        justify-content: space-between;
        border-bottom: 0.1vw solid #D1D1D1;

        >p {
            font-family: Jost;
            font-size: 1.2vw;
            margin-left: 2.5%;

        }

        >div {
            display: flex;
            align-items: end;
            justify-content: end;
            width: 20%;
            margin-right: 2.5%;
            cursor: pointer;

            p {
                margin-right: 5%;
                font-family: Comfortaa;
                font-size: 0.8vw;
            }

            img {
                width: 0.5vw;
            }
        }
    }

    body{
        height: 11vw;
        overflow: auto;
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 3%;
    }
}

.main-categorie-rendered{
    height: 2.7vw;
    padding: 0.3vw 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 0.1vw solid #D1D1D1;
    margin-left: 2.5%;
    width: 95%;

    /*imagem e nome*/
    >div{
        display: flex;
        align-items: center;
        height: 100%;
        width: 50%;

        p{
            font-family: Jost;
            font-size: jost;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 100%;
            margin-left: 2%;
            font-size: 1vw;
        }

        div{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 0.9vw;
            height: 0.9vw;
            padding: 0.3vw;
            border-radius: 100%;

            img{
                width: 100%;
            }
        }

    }

    /*valor*/
    >p{
        font-size: 1.1vw;
        font-family: Jost;

    }
}