.WindowFullSpendIncomeCardOverlay {
    margin-left: 11%;
    height: 93%;
    width: 87%;
    border-radius: 0.25vw;
    background: #F0F0F0;
    box-shadow: 0px 0.2vw 0.3vw 0px rgba(0, 0, 0, 0.25);
    display: flex;
    position: relative;
}

.FooterDesktopOpenFinances {
    position: absolute;
    border-radius: 0.3vw;
    height: 2.4vw;
    width: 15vw;
    bottom: 0.7vw;
    right: 9.3%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    box-shadow: 0px 0vw 0.3vw 0.02vw rgba(0, 0, 0, 0.25);

    div {
        width: 2.2vw;
        height: 2.2vw;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: -25%;
        border-radius: 50%;
        padding: 3%;
        box-shadow: 0px 0vw 0.3vw 0.02vw rgba(0, 0, 0, 0.25);

        img {
            width: 55%;
        }
    }

    p {
        color: #fafafa;
        font-family: Jost;
        font-size: 1.55vw;
        white-space: nowrap;
        font-weight: 500;
    }
}

.overflowFormsCreateUpdateFinanceSmartphone {
    display: none;
}

.hoverSelectCategorieSmartPhone {
    display: none;
}

.MainContainerOpenFinanceCard {
    width: 75%;
    position: relative;

}

.OpenFinancialCardsContainer {
    width: 90%;
    margin-left: 5%;
    max-height: 80%;
    padding-top: 0.3vw;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(16.66%, 1fr));
    grid-template-rows: repeat(auto-fill, minmax(auto, 5fr));
    overflow-y: auto;
}

.RightSideFilterAndForms {
    width: 25%;
    height: 100%;
    background-color: #FAFAFA;
    border-radius: 0 0.25vw 0.25vw 0;
    box-shadow: 0vw 0vw 0.1vw 0.01vw rgba(0, 0, 0, 0.25);
    position: relative;

    header {
        width: 90%;
        margin-left: 5%;
        height: 10%;
        display: flex;
        align-items: baseline;
        justify-content: space-between;
        position: relative;


        >div:nth-of-type(1) {
            font-family: Jost;
            font-size: 2vw;
            margin-top: 5%;
            white-space: nowrap;

            div {
                display: none;
            }

            img {
                display: none;
            }
        }

        >div:nth-of-type(2) {
            border-radius: 10%;
            background-color: #EEC842;
            width: 6.5vw;
            height: 2.3vw;
            display: flex;
            align-items: center;
            justify-content: space-around;
            box-shadow: 0px 0vw 0.3vw 0.02vw rgba(0, 0, 0, 0.25);
            transition: background-color 0.3s ease;
            cursor: pointer;

            >p {
                font-family: Jost;
                font-size: 1.1vw;
                color: white;
            }

            &:hover {
                background-color: #C7A630;
            }

            img {
                width: 1.5vw;
            }
        }
    }
}

.RightSideFilterAndForms form {
    width: 90%;
    margin-left: 5%;
    height: 80%;
}

.RightSideFilterAndForms footer {
    width: 90%;
    margin-left: 5%;
    height: 10%;

    >div {
        width: 100%;
        display: flex;
        justify-content: space-between;
        position: relative;
    }
}

.FormsFinanceViewButton {
    background-color: transparent;
    width: 100%;
    height: 60%;
    font-family: Jost;
    font-size: 1.3vw;
    border-radius: 0.1vw;
    display: flex;
    align-items: center;
    justify-content: space-around;
    cursor: pointer;
    font-weight: 500;
}

#CreateFinanceButton {
    border: solid 0.2vw #65C37A;
    color: white;
    background-color: #65C37A;
    transition: background-color 0.3s, border 0.3s;

    &:hover {
        background-color: #56a667;
        border: 0.2vw solid #56a667;
    }
}

#DeleteFinanceButton {
    background-color: #ED4C5C;
    border: solid 0.2vw #ED4C5C;
    color: white;
}

.checkTypeOfUpdateFinance {
    position: absolute;
    height: 2vw;
    width: 45%;
    right: 0;
    bottom: -1.9vw;
    z-index: 1000;

    li {
        font-family: Jost;
        list-style: none;
        text-align: center;
        font-size: 0.8vw;
        padding: 1% 0 1% 0;
        color: #ffffff;
        background-color: #5AA66A;

        &:hover {
            background-color: #236c33;
        }
    }
}

.checkTypeOfDeleteFinance {
    position: absolute;
    height: 2vw;
    width: 45%;
    left: 0;
    bottom: -1.9vw;
    z-index: 2;

    li {
        font-family: Jost;
        list-style: none;
        text-align: center;
        font-size: 0.8vw;
        padding: 1% 0 1% 0;
        color: #ffffff;
        background-color: #b31828;

        &:hover {
            background-color: #950f1c;
        }
    }
}

.marginTop5 {
    margin-top: 5%;
}

.formsCreateEditFinance {
    label {
        display: block;
        font-family: Jost;
        font-size: 1vw;
        margin-left: 2%;
    }

    input {
        background-color: rgba(255, 255, 255, 1);
        border: solid 0.1vw #242424;
        border-radius: 0.2vw;
        height: 1.7vw;
        color: #3b3b3b;
        font-size: 0.95vw;
        text-indent: 2%;
        font-family: Jost;
        width: 99%;

        &:focus {
            outline: none;
        }
    }

    img {
        width: 0.8vw;
        margin-left: 1%;
    }

    /*Div com o input de valor e de data*/
    >div:nth-of-type(2) {

        /*Div com o input de valor*/
        >div:nth-of-type(1) {
            margin-top: 1%;
            width: 50%;
        }

        /*Div com o input de data*/
        >div:nth-of-type(2) {
            margin-top: 3%;
            display: flex;
            justify-content: end;
            gap: 3%;


            >div {
                background-color: transparent;
                border-radius: 0.3vw;
                color: #EEC842;
                display: flex;
                white-space: nowrap;
                padding: 0.3vw 0.4vw;
                font-weight: 600;
                border: solid 0.15vw #EEC842;
                font-family: Jost;
                width: fit-content;
                justify-content: space-evenly;
                cursor: pointer;
                font-size: 0.8vw;
                align-items: center;
                height: 1vw;

                &:hover {
                    background-color: #EEC84226;
                    transition: background-color 0.3s ease, color 0.3s ease;
                }
            }

            >div:nth-of-type(1) {
                transition: width 0.3s ease;
                overflow: hidden;

                input {
                    border: none;
                    background-color: transparent;
                    height: 100%;
                    min-width: 100%;
                    padding: 0.3vw 0.4vw;
                    color: #242424;
                    text-align: center;
                }
            }
        }
    }


}

.detailFinancesContainer {
    width: 97%;
    display: flex;
    justify-content: end;
}


.buttonDetailFinance {
    margin-top: 2%;
    font-size: 0.75vw;
    color: #6C6C6C;
    text-decoration: underline;
    cursor: pointer;
    font-family: Jost;
    width: fit-content;
    user-select: none;
    display: flex;
    white-space: nowrap;

    img {
        margin-left: 3%;
        margin-top: 3%;
    }

}


#InputRecurrenceFinance {
    height: 3.5vw;
    width: 100%;

    section {
        display: flex;
        align-items: end;
        justify-content: space-between;
    }

    span {
        font-family: Jost;
        font-size: 1vw;
        margin-left: 2%;
    }

    >p {
        font-size: 0.8vw;
        font-family: jost;
        margin-left: 2%;
        color: #ED4C5C;
    }
}

.buttonRecurrenceDetails {
    display: flex;
    align-items: center;
    justify-content: space-around;

    margin-left: 1%;
    margin-top: 3%;

    width: 90%;
    height: 100%;

    background-color: rgba(255, 255, 255, 1);
    border: solid 0.1vw #242424;
    border-radius: 0.2vw;

    font-family: Jost;
    font-size: 0.95vw;
    cursor: pointer;

    position: absolute;
    z-index: 2;
    height: 1.8vw;

    img {
        width: 0.8vw;
    }
}

.InputEndRecurrenceFinance {
    position: relative;
    width: 60%;

    p {
        color: #3b3b3b;
    }
}

.setLimitDeactivateRecurrenceContainer {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.windowSetEndRecurrence {
    position: absolute;
    z-index: 1;
    width: 90%;
    height: 6vw;
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0px 0vw 0.3vw 0.02vw rgba(0, 0, 0, 0.25);
    border-radius: 0 0 0.3vw 0.3vw;
    border: none;
    margin-left: 1%;
    margin-top: 17%;

    input {
        height: 1.4vw;
        margin-top: 5%;
        width: 30%;
        margin-left: 33%;
        background-color: rgba(255, 255, 255, 1);
        border: solid 0.1vw #242424;
        border-radius: 0.2vw;
        text-align: center;
    }

    p {
        font-family: Jost;
        color: #242424;
        font-size: 0.75vw;
        text-align: center;
        margin-top: 3%;
    }

    div {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-around;
        height: 30%;

        button {
            width: 45%;
            height: 80%;
            margin-top: 5%;
            font-family: Jost;
            border-radius: 0.2vw;
            font-size: 0.9vw;
            border: none;
            box-shadow: 0px 0.05vw 0.05vw 0px rgba(0, 0, 0, 0.25);
            cursor: pointer;
            transition: background-color 0.3s, boder 0.3s ease;

            background-color: #EEC842;
            color: #ffffff;
            font-weight: 500;

            &:hover {
                background-color: #C7A630;
            }

        }

        >button:nth-of-type(2) {
            color: #242424;
        }
    }
}


#InputObservationFinance {
    margin-top: 2%;

    >textarea {
        background-color: rgba(255, 255, 255, 1);
        border: solid 0.1vw #242424;
        border-radius: 0.2vw;
        height: 1.7vw;
        margin-left: 0.5%;
        font-family: Jost;
        color: #3b3b3b;
        font-size: 0.9vw;
        max-width: 96.5%;
        padding-left: 2%;

        &:focus {
            outline: none;
        }
    }
}


#InputDropBoxFinance {
    height: 3.7vw;
    >div {
        width: 99%;
        height: 100%;

    }
}

.importFinancesWindowOpacityOverlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(36, 36, 36, 0.5);
    animation: fadeIn 0.5s forwards;
    border-radius: 0.22vw;
    z-index: 1;
}

.buttonUpdateFinanceContainer {
    width: 45%;
    height: 100%;
}


@media only screen and (max-width: 768px) {

    /*Visão de finanças*/
    .MainContainerOpenFinanceCard {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .OpenFinancialCardsContainer {
        margin-top: 2.5%;
        width: 100%;
        height: 75%;
        margin-left: 0%;
        display: flex;
        flex-direction: column;
        grid-template-columns: none;
        grid-template-rows: none;
        overflow-y: auto;
        overflow-x: hidden;
        user-select: none;
    }

    .FooterSmartPhoneOpenFinances {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 9%;
        background-color: #f0f0f0;

        button {
            font-family: Jost;
            font-weight: 400;
            padding: 0 5%;
            color: white;
            border-radius: 1vw;
            height: 5vw;
            width: 90vw;
            cursor: pointer;
            border: none;
            transition: background-color 0.3s, border 0.3s;
        }
    }


    /*Janela do formulário*/

    /*Lógica para esconder ou mostrar o formulário de criação de finança*/
    .overflowFormsCreateUpdateFinanceSmartphone {
        width: 100%;
        opacity: 0;
        visibility: hidden;
    }

    .overflowFormsCreateUpdateFinanceSmartphone.active {
        visibility: visible;
        animation: fadeIn 0.5s forwards;
    }

    .overflowFormsCreateUpdateFinanceSmartphone {
        position: absolute;
        z-index: 1;
        transition: width 0.3s ease;
        background-color: #FAFAFA;
        border-radius: 2vw;
        display: flex;
        flex-direction: column;
        height: 100%;

        >div:nth-of-type(1) {
            height: 95vh;
            overflow: auto;

        }

        header {
            /*Header formulário de criação*/
            width: 95%;
            margin-left: 2.5%;
            margin-top: 3%;
            height: 5%;

            >div:nth-of-type(1) {
                /*Título e botão de voltar*/
                justify-content: space-between;
                display: flex;
                align-items: center;
                width: 90%;
                margin-left: 5%;

                img {
                    transform-origin: center;
                    width: 6.5%;
                    height: 10vw;
                }

                >p {
                    font-family: Comfortaa;
                    font-size: 6.5vw;
                    font-weight: 500;
                    white-space: nowrap;
                }
            }

            >div:nth-of-type(2) {
                /*import*/
                display: none;

                img {
                    display: none;
                }
            }
        }

        footer {
            width: 100%;
            background-color: transparent;
            height: 8vh;
            bottom: 0;
            display: flex;
            align-items: center;

            >div {
                gap: 5%;
                height: 100%;
                width: 90%;
                margin-left: 5%;
                display: flex;
                justify-content: space-between;
            }
        }
    }

    .WindowFullSpendIncomeCardOverlay {
        margin-left: 0%;
        height: 100%;
        width: 100%;
    }

    .formsCreateEditFinance {
        position: relative;
        width: 90%;
        margin-left: 5%;
        margin-top: -2%;
        padding-right: 1%;
        overflow-y: auto;
        overflow-x: hidden;
        transition: 0.3 ease;
        min-height: 89vh;

        /*red*/
        label {
            display: block;
            font-family: Jost;
            font-size: 4.5vw;
            margin-left: 1%;
        }

        input {
            height: 9.2vw;
            font-size: 4.3vw;
            text-indent: 2%;
            margin-top: 0%;
            font-family: Jost;
            border-radius: 2vw;

            &:focus {
                outline: none;
            }
        }

        >div:nth-of-type(2) {

            /*Div com o input de valor*/
            >div:nth-of-type(1) {
                width: 40%;

                input {
                    width: 100%;
                }
            }

            /*Div com o input de data*/
            >div:nth-of-type(2) {
                margin-top: 3%;
                display: flex;
                justify-content: end;
                gap: 3%;

                >div {
                    border-radius: 2vw;
                    padding: 1vw 1.5vw;
                    font-size: 4vw;
                    border: solid 2px #EEC842;
                    height: 5vw;
                }

                >div:nth-of-type(1) {
                    transition: width 0.3s ease;
                    overflow: hidden;

                    input {
                        border: none;
                        background-color: transparent;
                        height: 100%;
                        min-width: 100%;
                        padding: 0.3vw 0.4vw;
                        color: #242424;
                        text-align: center;
                    }
                }
            }
        }
    }

    .detailFinancesContainer {
        width: 100%;
        display: flex;
        justify-content: end;
    }


    .buttonDetailFinance {
        margin-top: 2%;
        font-size: 3.2vw;

        img {
            width: 3vw;
        }
    }


    #InputRecurrenceFinance {
        height: 12vw;
        margin-top: 2%;

        span {
            font-size: 4.5vw;
        }

        >p{
            font-size: 3.2vw;
        }
    }

    .buttonRecurrenceDetails {
        font-family: Jost;
        font-size: 3.8vw;
        height: 8vw;
        box-shadow: 0px 0vw 0.8vw 0.3vw rgba(0, 0, 0, 0.25);
        border-radius: 2vw;
        width: 85%;

        img {
            width: 3.5vw;
        }
    }


    .windowSetEndRecurrence {
        height: 30vw;
        padding: 2%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border-radius: 0 0 0.6vw 0.6vw;
        width: 81%;
        margin-top: 16%;

        input {
            margin-top: 3%;
        }

        p {
            font-size: 3.5vw;
        }

        >div {

            button {
                width: 45%;
                height: 70%;
                margin-top: 0%;
                border-radius: 1vw;
                font-size: 3.5vw;
            }
        }
    }

    #InputObservationFinance {
        margin-top: 5%;

        textarea {
            min-height: 20vw;
            border-radius: 2vw;
            font-size: 4.3vw;
            width: 100%;
        }
    }


    .creditCardPaddingSmartphone {
        margin-top: 1%;
    }

    #InputDropBoxFinance {
        height: 18vw;
        width: 98%;
        margin-left: 1%;

        >div {
            position: absolute;
            width: 98%;
            left: 1%;
            height: 10vw;

            >div {
                border-radius: 1vw;
                height: 8vw;
            }
        }
    }




    .FormsFinanceViewButton {
        width: 100%;
        min-height: 9vw;
        font-size: 6vw;
        font-weight: 500;
        border-radius: 1vw 1vw 1vw 1vw;
        white-space: nowrap;
    }

    .checkTypeOfUpdateFinance {
        li {
            font-size: 3.7vw;
            height: 7vw;
        }
    }

    .checkTypeOfDeleteFinance {
        li {
            font-size: 3.7vw;
            height: 7vw;
        }
    }
}