.LoginForms {

    header {
        h1 {
            font-family: 'Comfortaa', sans-serif;
            font-size: 2.2vw;
            letter-spacing: -0.03em;
        }

        p {
            font-family: "Open Sans", sans-serif;
            font-size: 1vw;
            margin-top: 1vw;
            margin-bottom: 2vw;
        }

        span {
            color: #EEC842;
            cursor: pointer;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    >footer {
        width: 100%;
        height: 35%;
        margin-top: 10%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: end;

        >div {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            >button {
                width: 13vw;
                height: 2.2vw;
                border-radius: 3vw;
                box-sizing: border-box;
                background-color: #EEC842;
                cursor: pointer;
                color: #fafafa;
                font-weight: bold;
                font-family: 'Comfortaa', sans-serif;
                font-size: 1.1vw;
                user-select: none;
                border: none;
                box-shadow: rgb(238, 200, 66, 0.6) 0 0.4vw 1vw -0.4vw;
                transition: background-color 0.3s ease, color 0.3s ease, font-weight 0.1s ease;

                &:hover {
                    transition: 0.5s;
                    background-color: #C7A630;
                }
            }
        }

        >a {
            font-family: Jost;
            color: #bababa;
            font-size: 1vw;
            margin-top: 3%;
            &:hover {
                cursor: pointer;
                text-decoration: underline;
            }
        }

    }
}

.errorMessageFormsLogin {
    margin-top: 1vw;
    font-family: Jost;
    color: red;
    font-size: 1vw;
    width: 28vw;
}

@media only screen and (max-width: 768px) {

    .LoginForms {
        header {
            h1 {
                font-size: 6.5vw;
            }

            p {
                font-size: 3.5vw;
            }
        }

        section {
            margin-top: 10%;
        }

        footer {
            margin-top: 10%;
            align-items: center;

            >div{
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 3vw;

                >button {
                    font-size: 5vw;
                    font-weight: 500;
                    width: 60vw;
                    height: 10vw;
                    margin-top: 5%;
                    border-radius: 10vw;
                    margin-top: 5%;
                }
            }

            a {
                font-size: 3.5vw;
                margin-top: 5vw;
            }

        }
    }

    .errorMessageFormsLogin {
        font-size: 3vw;
        min-width: 70vw;
    }
}