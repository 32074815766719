.FormsChangePassword {

    h1 {
        font-family: 'Comfortaa', sans-serif;
        font-size: 2.2vw;
        letter-spacing: -0.03em;
        display: flex;
    }

    p {
        width: 90%;
        font-family: "Open Sans", sans-serif;
        font-size: 1vw;
        margin-top: 1vw;
        text-align: justify;
    }

    span {
        font-family: "Open Sans", sans-serif;
        font-size: 1vw;
        color: #EEC842;
    }

    section {
        margin: 2vw 0 1vw 0;
        display: flex;
        flex-direction: column;
    }

    footer {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: baseline;

        button {
            width: 10vw;
            height: 2.2vw;
            border-radius: 3vw;
            box-sizing: border-box;
            background-color: #EEC842;
            cursor: pointer;
            color: #fafafa;
            font-weight: bold;
            font-family: 'Comfortaa', sans-serif;
            font-size: 1.1vw;
            user-select: none;
            border: none;
            box-shadow: rgb(238, 200, 66, 0.6) 0 0.4vw 1vw -0.4vw;
            transition: background-color 0.3s ease, color 0.3s ease, font-weight 0.1s ease;

            &:hover {
                transition: 0.5s;
                background-color: #C7A630;
            }
        }

        a {
            font-family: Jost;
            font-size: 1vw;
            color: #bababa;
            text-decoration: underline;
            cursor: pointer;
        }
    }
}

#errorMessageChangePassword {
    font-family: Jost;
    font-size: 1vw;
    color: red;
}

#messageChangePassword {
    font-family: Jost;
    font-size: 1vw;
    color: green;
}

@media only screen and (max-width: 768px) {
    .FormsChangePassword {

        h1 {
            font-size: 6.5vw;
        }

        p {
            text-align: left;
            font-size: 3.5vw;
        }

        span {
            font-size: 3.5vw;
        }

        section {
            margin: 2vw 0 1vw 0;
            display: flex;
            flex-direction: column;
        }

        footer {

            button {
                font-size: 4vw;
                font-weight: 500;
                width: 30vw;
                height: 8vw;
            }

            a {
                font-size: 3.5vw;
            }
        }
    }

    #errorMessageChangePassword {
        font-size: 3vw;
    }

    #messageChangePassword {
        font-size: 3vw;
    }
}