.allGroupCreate {
    width: 100%;
    background-color: #fff;
    overflow: hidden;
    height: calc(var(--vh, 1vh) * 100);
    padding-top: env(safe-area-inset-top);
    padding-bottom: env(safe-area-inset-bottom);
    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);
    position: fixed;
    z-index: 10;

    >div:nth-of-type(2) {
        display: flex;
        height: 90%;
        justify-content: space-between;
        >div:nth-of-type(1) {
            width: 55%;
            height: 100%;
            display: flex;
            justify-content: center;

            img {
                user-select: none;
                width: 42vw;
                height: 42vw;
            }

        }

        >div:nth-of-type(2) {
            width: 35%;
            margin-right: 5%;
            height: 90%;
            display: flex;
            justify-content: center;
            flex-direction: column;
            border-radius: 1vw;

            >header {
                height: 12.5%;
                padding: 1vw;

                >p:nth-of-type(1) {
                    font-family: Comfortaa;
                    text-align: justify;
                    font-size: 1.5vw;
                    font-weight: 600;
                }

                >p:nth-of-type(2) {
                    font-family: Jost;
                    font-size: 1.1vw;
                    margin-top: 1%;
                    color: #575757;
                }
            }

            >div{
                display: flex;
                flex-direction: column;
                justify-content: center;
                overflow-y: auto;
                height: 90%;
            }
        }

    }
}

@media only screen and (max-width: 768px) {
    .allGroupCreate {
        width: 100%;
        background-color: #FFFFFF;

        >div:nth-of-type(2) {
            display: flex;
            justify-content: center;
            height: 90%;

            >div:nth-of-type(1) {
                width: 0%;

                img {
                    display: none;
                }

            }

            >div:nth-of-type(2) {
                width: 100%;
                display: flex;
                justify-content: center;
                overflow-y: auto;
                height: 100%;
                box-shadow: none;
                margin-left: 5%;

                >div{
                    height: 80%;
                }

                >header {
                    height: 10%;
                    padding: 3vw;

                    p:nth-of-type(1) {
                        width: 100%;
                        font-size: 5vw;
                        font-weight: 600;
                    }
        
                    p:nth-of-type(2) {
                        width: 100%;
                        font-size: 4vw;
                    }
                }
            }
        }
    }
}
