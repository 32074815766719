.selectMonthContainer {
    background-color: #fafafa;
    box-shadow: 0 0 0.4vw 0 rgba(0, 0, 0, 0.25);
    border-radius: 0.2vw;
    height: 2.2vw;
    transition: 0.3s ease-out;
    position: relative;

    >button {
        cursor: pointer;
        width: 100%;
        height: 100%;

        border: none;
        background-color: transparent;

        display: flex;
        justify-content: space-between;
        align-items: center;

        &:hover {
            background-color: #2424240D;
        }


        >div:nth-of-type(1) {
            width: 7.5vw;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            >p {
                /*Month*/
                font-family: Jost;
                font-size: 1.3vw;
                font-weight: 400;
                width: fit-content;
                text-align: center;
                position: relative;
            }

        }

        >div:nth-of-type(2) {
            height: 70%;
            width: 1px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #242424;

        }

        >div:nth-of-type(3) {
            height: 70%;
            width: 2.5vw;
            display: flex;
            align-items: center;
            justify-content: center;

            >img {
                width: 1vw;
            }
        }


    }
}

#yearSelectedWindow {
    /*Year*/
    position: absolute;
    font-size: 0.8vw;
    left: 0vw;
    top: -1vw;
    font-weight: 500;
    color: #9A9A9A;
    font-family: Jost;
    transition: left 0.3s ease;
}

.OpenWindowChangeMonth {
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-family: Jost;
    height: 100%;
    user-select: none;

    p {
        cursor: pointer;
        user-select: none;
    }

    img {
        cursor: pointer;
    }

    >img:nth-of-type(1) {
        rotate: 90deg
    }

    >img:nth-of-type(2) {
        rotate: 270deg
    }

    p:nth-of-type(3),
    p:nth-of-type(1) {
        font-size: 0.9vw;
        width: 4vw;
        text-align: center;
        color: #555555;
        /* Escolha apenas uma cor */
    }

    >p:nth-of-type(2) {
        font-size: 1.2vw;
    }
}

@media only screen and (max-width: 768px) {

    .changeMonthContainerSmartphone {
        display: block;
        width: 70%;
        transition: 0.3s ease;
        margin-bottom: 5%;

        button {
            width: 90%;
            margin-left: 5%;
            border: none;
            background-color: transparent;
            display: flex;
            align-items: baseline;
            justify-content: space-between;

            p {
                font-family: Jost;
            }

            img {
                width: 3vw;
            }

            >p {
                /*month*/
                font-size: 5.5vw;
                position: relative;
                color: #242424;

                >p {
                    /*year*/
                    position: absolute;
                    font-size: 3vw;
                    bottom: -2.5vw;
                    right: 0;
                    color: #9A9A9A;
                }
            }


        }
    }

    .OpenWindowChangeMonth {
        border-bottom: 0.1vw solid #d8d8d8;
        user-select: none;

        p {
            cursor: default;
            user-select: none;
        }

        img {
            cursor: default;
            width: 2vw;
        }

        p:nth-of-type(3),
        p:nth-of-type(1) {
            font-size: 4.5vw;
            width: 22vw;
            text-align: center;
            color: #555555;
        }

        >p:nth-of-type(2) {
            font-size: 6vw;

        }

    }
}