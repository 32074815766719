.MainIncomeAndSpendPart {
    width: 100%;
}

.MainIncomeSpendFullCardClick {
    cursor: default;
    position: relative;
    animation: fadeIn 0.4s forwards;
    opacity: 0;
}

.MainIncomeSpendFullCardOverlay {
    background-color: rgba(0, 0, 0, 0.4);
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0vw;
    left: 0px;
    z-index: 3;
    display: flex;
    align-items: center;
    justify-content: center;

}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.BalanceIncomeAndSpend {
    margin-top: 3%;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 2vw;
    height: 23.8vw;

    .BalanceTopVision {
        display: flex;
        gap: 2%;
        position: relative;
        width: 70%;

        >div:nth-of-type(1) {
            width: 17vw;
        }

        >div:nth-of-type(3) {
            margin-left: 6%;
            min-width: 15vw;
            min-height: 100%;
        }

        >div:nth-of-type(4) {
            min-width: 15vw;
            min-height: 100%;
        }


        .grey-line-total-income-total-spend {
            position: absolute;
            background-color: #D9D9D9;
            min-height: 0.1vw;
            width: 48.5%;
            top: 72.5%;
            left: 38%;
        }
    }

    .IncomeAndSpendView {
        display: flex;
        gap: 2%;
        height: 17vw;
    }

}

.AccountsRightSide {
    min-width: 21%;
    height: 130%;
    margin-top: -5.1vw;

    >div {
        height: 100%;

    }
}



.CategoriesCreditCardEstatisticsSection {
    display: flex;
    margin-top: 3%;
    gap: 2.5%;
    height: 15vw;

    /*Credit card*/
    >div:nth-of-type(1) {
        display: flex;
        flex-direction: column;
        width: 50%;
    }

    >div:nth-of-type(2) {
        height: 100%;
        width: 50%;
    }
}


.ShortFinancialCard {
    cursor: pointer;
    width: 100%;
    height: 100%;
}




@media (max-width: 768px) {


    .MainIncomeSpendFullCardClick {
        cursor: default;
        position: relative;
        animation: fadeIn 0.4s forwards;
        opacity: 0;
    }

    .MainIncomeSpendFullCardOverlay {
        background-color: rgba(0, 0, 0, 0.4);
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0px;
        left: 0px;
        z-index: 3;
        display: flex;
        align-items: center;
        justify-content: center;

    }

    .BalanceIncomeAndSpend {
        width: 100%;
        display: flex;
        flex-direction: column;
        height: 100%;

        .BalanceTopVision {
            display: flex;
            flex-direction: column;
            gap: 2%;
            position: relative;
            width: 100%;
            height: auto;
            
            >div:nth-of-type(1) {
                width: 100%;
            }

            >div:nth-of-type(3) {
                margin-left: 0%;
                min-width: 100%;
                min-height: auto;
                margin-top: 1vw;
            }

            >div:nth-of-type(4) {
                min-width: 100%;
                min-height: auto;
            }


            .grey-line-total-income-total-spend {
                display: none;
            }
        }

        .IncomeAndSpendView {
            display: flex;
            flex-direction: column;
            gap: 3vw;
            margin-top: 5%;
            height: 171vw;

            .ShortFinancialCard {
                cursor: pointer;
                width: 95%;
                margin-left: 2.5%;
                height: 56vw;
            }

            .AccountsRightSide {
                min-width: 95%;
                max-width: 95%;
                margin-left: 2.5%;
                height: 50vw;
                margin-top: 3vw;

                >div {
                    height: 100%;
                }
            }
        }
    }

    .CategoriesCreditCardEstatisticsSection {
        display: flex;
        margin-top: 3vw;
        gap: 0%;
        height: 50vw;
        width: 95%;
        margin-left: 2.5%;
        padding-bottom: 15vh;

        /*Credit card*/
        >div:nth-of-type(1) {
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 100%;
        }
        /*Main categories*/
        >div:nth-of-type(2) {
            display: none;
        }
    }
}