.estatisticsSectionContainer {
    width: 100%;
    height: 100%;
    overflow-y: auto;

    #categories-in-month-title-estatistics {
        text-align: center;
        font-family: Jost;
        font-size: 2vw;
        font-weight: 400;
        border-bottom: solid 0.1vw #D1D1D1;
        margin-top: 2%;
        width: 98%;
        margin-left: 1%;
    }

    .categories-in-month-container {
        height: 16vw;
        width: 100%;
        display: flex;
        gap: 3%;
        width: 98%;
        margin-left: 1%;
        margin-top: 2%;

        >div {
            width: 50%;
        }
    }

    >section:nth-of-type(1) {
        /*Section one*/
        margin-top: 3%;
        width: 98%;
        margin-left: 1%;

        >p:nth-of-type(1) {
            text-align: center;
            font-family: Jost;
            font-size: 2vw;
            font-weight: 400;
            border-bottom: solid 0.1vw #D1D1D1;
        }

        >div {
            margin-top: 2%;
            height: 26vw;
            width: 100%;
            transition: width 0.3s, height 0.3s;

        }
    }

    >section:nth-of-type(2) {
        /*Section two*/
        margin-top: 3%;
        height: 35vw;
        width: 98%;
        margin-left: 1%;

        >p:nth-of-type(1) {
            text-align: center;
            font-family: Jost;
            font-size: 2vw;
            font-weight: 400;
            border-bottom: solid 0.1vw #D1D1D1;
        }

        >div {
            display: flex;
            justify-content: space-between;
            padding-right: 2%;
            /*corrigindo a sombra do card, para não ultrapassar o tamanho da tela*/

            >div {
                /*Separando os componentes de saldo e categorias*/
                margin-top: 2%;
                height: 27vw;
                width: 48%;
                transition: width 0.3s, height 0.3s;
            }
        }
    }
}

.cursorDefault {
    cursor: default;
}


@media only screen and (max-width: 768px) {

    .estatisticsSectionContainer {

        #categories-in-month-title-estatistics {
            font-size: 6vw;
            margin-top: 2%;
            width: 95%;
            margin-left: 2.5%;
        }

        .categories-in-month-container {
            height: 80vh;
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 3%;
            width: 98%;
            margin-left: 1%;
            margin-top: 5%;

            >div {
                width: 98%;
                margin-left: 1%;
                height: 50%;
            }
        }
    }

    .estatisticsSectionContainer {
        margin-left: 0%;
        overflow-x: hidden;

        >section:nth-of-type(1) {
            /*Section two*/
            margin-top: 5%;
            width: 95%;
            margin-left: 3%;

            >p:nth-of-type(1) {
                font-size: 6vw;
            }

            >div {

                margin-top: 4%;
                height: 120vw;
                width: 100%;
                transition: width 0.3s, height 0.3s;

                &:hover {
                    height: 120vw;
                    width: 100%;
                }
            }
        }

        >section:nth-of-type(2) {
            /*Section one*/
            margin-top: 20%;
            height: 300vw;
            width: 97%;
            margin-left: 3%;

            >p:nth-of-type(1) {
                font-size: 6vw;
                margin-bottom: -5%;
            }

            >div {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                padding-right: 2%;
                /*corrigindo a sombra do card, para não ultrapassar o tamanho da tela*/

                >div {
                    /*Separando os componentes de saldo e categorias*/
                    height: 150vw;
                    width: 100%;
                    margin-top: 10%;
                    transition: width 0.3s, height 0.3s;

                    &:hover {
                        height: 150vw;
                        width: 100%;
                    }
                }

                >div:nth-of-type(2) {
                    padding-bottom: 30vw;

                }
            }
        }
    }
}