.update-view-main-container {
    display: flex;
    overflow: auto;
    flex-direction: column;
    gap: 1vw;
    padding: 1vw;
}

.update-view-message-card {
    background-color: #fafafa;
    box-shadow: 0vw 0vw 0.5vw 0 rgba(0, 0, 0, 0.25);
    border-radius: 0.3vw;
    overflow: auto;
    cursor: pointer;
    transition: height 0.4s ease;

    >header {
        height: 5vw;
        display: flex;
        justify-content: space-between;

        /*img, title e subtitle*/
        >div {
            display: flex;
            width: 90%;
            overflow: hidden;
            align-items: center;
            gap: 2%;

            >img {
                margin-left: 2.5%;
                width: 2.75vw;
                height: 2.75vw;
                border-radius: 50%;
            }

            >div {
                display: flex;
                flex-direction: column;
                justify-content: center;
                font-family: Jost;
                height: 60%;

                >h1 {
                    font-weight: 500;
                    font-size: 1.3vw;
                }

                >h2 {
                    font-weight: 400;
                    font-size: 0.8vw;
                    color: #848484;
                }
            }
        }

        /*lido*/
        >p {
            font-family: Jost;
            font-size: 0.8vw;
            color: #848484;
            margin-right: 2.5%;
            margin-top: 1%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 0.2vw;
            padding: 0 1vw;
            white-space: nowrap;

            /*bolinha vermelha*/
            div {
                border-radius: 50%;
                width: 0.5vw;
                height: 0.5vw;
                background-color: rgb(208, 57, 57);
            }
        }
    }

    >body {
        width: 95%;
        margin-left: 2.5%;

        text {
            font-family: Jost;
            font-size: 0.85vw;
            display: flex;
            flex-direction: column;
            gap: 0.3vw;
            text-align: justify;
        }
    }
}


@media only screen and (max-width: 768px) {


    .update-view-message-card {
        box-shadow: 0vw 0vw 1vw 0 rgba(0, 0, 0, 0.25);
        border-radius: 1vw;

        >header {
            height: auto;
            display: flex;
            justify-content: space-between;

            /*img, title e subtitle*/
            >div {
                display: flex;
                width: 80%;
                overflow: hidden;
                align-items: center;
                gap: 2%;
                margin-top: 2vw;

                >img {
                    margin-left: 2.5%;
                    width: 10vw;
                    height: 10vw;
                    min-width: 10vw;
                    height: 10vw;
                    border-radius: 50%;
                }

                >div {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    font-family: Jost;
                    height: 60%;

                    >h1 {
                        font-weight: 500;
                        font-size: 4vw;
                    }

                    >h2 {
                        font-weight: 400;
                        font-size: 3vw;
                        color: #848484;
                    }
                }
            }

            /*lido*/
            >p {
                font-family: Jost;
                font-size: 3vw;
                color: #848484;
                margin-right: 2.5%;
                margin-top: 1%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 1vw;
                padding: 0 1vw;
                white-space: nowrap;

                /*bolinha vermelha*/
                div {
                    border-radius: 50%;
                    width: 1.5vw;
                    height: 1.5vw;
                    background-color: rgb(208, 57, 57);
                }
            }
        }

        >body {
            width: 95%;
            margin-left: 2.5%;
            margin-top: 2.5%;

            text {
                font-family: Jost;
                font-size: 3vw;
                display: flex;
                flex-direction: column;
                gap: 1vw;
                text-align: justify;
            }
        }
    }

}