.AccountContainerCard {
    height: auto;
    width: 100%;

    header {
        display: flex;
        height: 3.5vw;
        align-items: center;
        justify-content: space-between;
        width: 95%;
        margin-left: 2.5%;

        >p {
            font-family: Jost;
            font-size: 1.25vw;
        }
    }

    /*line*/
    >div:nth-of-type(1) {
        height: 0.1vw;
        width: 100%;
        background-color: #D1D1D1;
    }

    >body {
        max-height: 16.5vw;
        overflow: auto;
    }
}

.accountUserContainer {
    display: flex;
    font-family: Jost;
    align-items: center;
    justify-content: space-between;
    width: 95%;
    margin-left: 2.5%;
    margin-top: 3%;
    padding-bottom: 3%;
    border-bottom: #D1D1D1 solid 1px;
    height: 3.3vw;

    >div {
        display: flex;
        align-items: center;
        width: 50%;


        >img {
            width: 2.1vw;
            height: fit-content;
            border-radius: 0.3vw;
        }

        >div {
            margin-left: 2%;

            >p:nth-of-type(1) {
                font-size: 0.9vw;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 8vw;
            }

            >p:nth-of-type(2) {
                font-size: 0.75vw;
                color: #636363;
                cursor: pointer;
            }
        }
    }

    >p {
        font-size: 0.9vw;
        width: 35%;
        max-width: 45%;
        text-align: right;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}


@media (max-width: 768px) {
    .AccountContainerCard {

        header {
            display: flex;
            height: 20%;
            align-items: center;
            justify-content: space-between;
            width: 95%;
            margin-left: 2.5%;

            >p {
                font-family: Jost;
                font-size: 5.7vw;
            }
        }

        /*line*/
        >div:nth-of-type(1) {
            height: 1px;
            width: 100%;
        }

        >body {
            max-height: 79%;
        }
    }

    .accountUserContainer {
        display: flex;
        font-family: Jost;
        align-items: center;
        justify-content: space-between;
        width: 95%;
        margin-left: 2.5%;
        margin-top: 2%;
        padding-bottom: 2%;
        border-bottom: #D1D1D1 solid 1px;
        height: 12vw;

        >div {
            width: 60%;

            >img {
                width: 8vw;
                border-radius: 1vw;
            }

            >div {
                margin-left: 2%;

                >p:nth-of-type(1) {
                    font-size: 3.9vw;
                    max-width: 53vw;
                }

                >p:nth-of-type(2) {
                    font-size: 2.9vw;
                }
            }
        }

        >p {
            font-size: 4vw;
            max-width: 45%;
        }
    }
}