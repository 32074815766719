.Section4AboutUs {
    >div:nth-of-type(1) {
        position: relative;
        z-index: -1;
        background-color: #EEC842;
        position: absolute;
        height: 20vh;
        left: -8%;
        width: 130%;
        transform: rotate(-1deg);

        img {
            position: absolute;
            right: 25%;
            transform: rotate(1deg);
            bottom: -17%;
            width: 40vh;
            height: 40vh;
        }
    }

    >div:nth-of-type(2) {
        padding: 2.5%;
        display: flex;
        justify-content: left;

        h1 {
            margin-left: 5%;
            font-family: Jost;
            font-size: 4.5vw;
            color: #FFFFFF;
            padding-bottom: 40%;

        }
    }
}