@keyframes fadeOut {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.main-container-error-message {
    border: solid 0.1vw red;
    position: absolute;
    width: max-content;
    bottom: -5%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #EEC842;
    height: 2.6vw;
    display: flex;
    padding: 0 2% 0 2%;
    align-items: center;
    justify-content: space-between;
    border-radius: 0.3vw;
    box-shadow: 0vw 0vw 0.3vw 0.05vw rgba(0, 0, 0, 0.25);
    animation: fadeOut 0.4s forwards;
    z-index: 999;

    p {
        font-family: Jost;
        font-size: 1.25vw;
        font-weight: 700;
        color: #fafafa;
        white-space: nowrap;
    };

    >div{
        width: 1vw;
        height: 1vw;
    }

    img {
        height: 55%;
    }

}

@media only screen and (max-width: 768px) {
    .main-container-error-message {
        height: 10vw;
        padding: 0 2% 0 2%;
        max-width: 80vw;
        bottom: 2.5%;
        border-radius: 2vw;

        p {
            font-size: 4.2vw;
            word-wrap: break-word;
            overflow-wrap: break-word;
        }
    }
}