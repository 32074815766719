.Section1AboutUs {
    display: flex;
    justify-content: space-around;
    margin-top: 1%;
    margin-bottom: 6.5%;

    img {
        width: 35vw;
        height: 30vw;
        margin-left: 10%;
    }

    div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        h1 {
            color: #383227;
            font-family: Jost;
            font-size: 2vw;
            font-weight: 600;
            text-align: right;
            margin-bottom: 2%;
            width: 65%;
        }

        h2 {
            font-weight: 400;
            font-size: 1.09vw;
            font-family: Jost;
            text-align: right;
            width: 65%;
        }
        
    }
}

/* Tablet or small screens settings */
@media only screen and (max-width: 1000px) {
    .Section1AboutUs {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 1%;
        margin-bottom: 6.5%;
        height: 60vh;
    
        img {
            width: 70%;
            height: 40vh;
            margin-left: 0;
        }
    
        div {

            h1 {
                color: #383227;
                font-family: Jost;
                font-size: 3.5vw;
                font-weight: 600;
                text-align: center;
                margin: 2% 0;
                width: 65%;
            }
    
            h2 {
                font-weight: 400;
                font-size: 2vw;
                font-family: Jost;
                text-align: center;
                width: 65%;
            }
            
        }
    }
    
}

/* Mobile settings */
@media only screen and (max-width: 768px) {
    .Section1AboutUs {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 130vw;
    
        img {
            width: 80%;
            height: 90vw;
            margin-top: 10vw;
            margin-left: 0;
        }
    
        div {
            margin-top: 10%;
            h1 {
                color: #383227;
                font-family: Jost;
                font-size: 5vw;
                font-weight: 600;
                text-align: center;
                width: 65%;
            }
    
            h2 {
                font-weight: 400;
                font-size: 4vw;
                font-family: Jost;
                text-align: center;
                width: 65%;
            }
            
        }
    }
}