:root {
    --vh: 100vh; /* Valor inicial */
  }
  
  .mainContainerLP {
      width: 100%;
      overflow-x: hidden;
      height: calc(var(--vh, 1vh) * 100); 
      padding-top: env(safe-area-inset-top);
      padding-bottom: env(safe-area-inset-bottom);
      padding-left: env(safe-area-inset-left);
      padding-right: env(safe-area-inset-right);
  }
  
  @media only screen and (max-width: 1366px) {

  }
  
  @media only screen and (max-width: 768px) {
    
  }
  