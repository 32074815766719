.mainContainerFormsCreateNewUser {
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: start;
    padding: 1vw;


    >body {
        margin-top: 2%;
        height: 85%;
        width: 100%;
        padding-right: 1%;
        overflow-y: auto;
    }

    >footer {
        height: 15%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        >p{
            font-family: Jost;
            font-size: 1.2vw;
            color: #646464;
        }

        >button {
            width: 13vw;
            height: 2.5vw;
            border-radius: 3vw;
            box-sizing: border-box;
            background-color: #EEC842;
            cursor: pointer;
            color: #fafafa;
            font-weight: bold;
            font-family: 'Comfortaa', sans-serif;
            font-size: 1.3vw;
            padding: 0.5vw 1vw;
            user-select: none;
            border: none;
            box-shadow: rgb(238, 200, 66, 0.6) 0 0.4vw 1vw -0.4vw;
            transition: background-color 0.3s ease, color 0.3s ease, font-weight 0.1s ease;

            &:hover {
                transition: 0.5s;
                background-color: #C7A630;
            }
        }
    }
}



/* Tablet or small screens settings */
@media only screen and (max-width: 768px) {
    .mainContainerFormsCreateNewUser {
        height: 100%;
        padding: 3vw;
        justify-content: space-between;

        >body {
            margin-top: 2%;
            width: 100%;
            overflow-y: auto;
            height: 80%;
        }


        >footer {
            height: 20%;
            width: 100%;
            display: flex;
            align-items: center;
            flex-direction: column;
            
            >p{
                font-size: 4vw;
            }

            button {
                margin: 0;
                padding: 0;
                font-size: 6vw;
                width: 80%;
                height: 10vw;
                border-radius: 8vw;
                box-shadow: 0.1vw 0.2vw 0.2vw;
            }
        }
    }
}