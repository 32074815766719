
.shakenotification {
    animation: tilt-shaking 0.3s infinite;
}

.HeaderSummaryDashboard {
    width: 100%;
    height: 100%;
    margin-top: 1%;
    display: flex;
    align-items: end;
    justify-content: space-between;
}

.UserDetailsImageProfile {
    display: flex;
    align-items: end;
    text-decoration: none;
    cursor: pointer;
    gap: 6%;

    .user-profile-image-overflow {
        min-width: 2.5vw;
        min-height: 2.5vw;
        max-width: 2.5vw;
        max-height: 2.5vw;
        border-radius: 50%;
        overflow: hidden;
        border: 0.1vw solid #EEC842;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            box-shadow: 0.1vw 0.5vw 1vw 0 rgba(0, 0, 0, 0.25);
        }
    }

    p {
        color: #636363;
        white-space: nowrap;
        font-family: 'Comfortaa', sans-serif;
        font-size: 1vw;
    }

    :last-child {
        color: #EEC842;
    }
}


.header-dashboard-view {
    justify-content: right;
    display: flex;
    align-items: center;
    margin-top: 1%;
    gap: 5%;
    width: 25%;

    /*Notifications*/
    .notificationsButton {
        height: 100%;
        display: flex;
        position: relative;
        cursor: pointer;
        margin-right: 10%;

        img {
            width: 1.2vw;
        }

        >div {
            position: absolute;
            background-color: #fafafa;
            height: 0.4vw;
            width: 0.4vw;
            padding: 0.15vw;
            border-radius: 50%;
            right: -0.25vw;
            bottom: -0.1vw;

            div {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                height: 0.4vw;
                width: 0.4vw;
                border-radius: 50%;
                background-color: red;
            }
        }
    }
}

@media (min-width: 1025px) and (max-width: 1366px) {}

@media only screen and (max-width: 1000px) {}

@media only screen and (max-width: 768px) {

    .HeaderSummaryDashboardSmartphone {
        width: 90%;
        margin-left: 5%;
        margin-top: 3%;
        height: 97%;
        display: flex;
        flex-direction: column;
        align-items: end;
        justify-content: space-between;

        >section {
            width: 100%;
            height: 50%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            >img {
                /*Logo Sunna*/
                width: 30%;
            }

            >div {
                display: flex;
                align-items: center;
                width: 25%;
                justify-content: space-between;

                /*Notifications*/
                .notificationsButton {
                    height: 100%;
                    display: flex;
                    position: relative;
                    cursor: pointer;
                    margin-right: 10%;

                    img {
                        width: 5vw;
                    }

                    >div {
                        position: absolute;
                        background-color: #fafafa;
                        height: 2vw;
                        width: 2vw;
                        padding: 0.6vw;
                        border-radius: 50%;
                        right: -1vw;
                        bottom: -0.2vw;

                        div {
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            height: 2vw;
                            width: 2vw;
                            border-radius: 50%;
                            background-color: red;
                        }
                    }
                }

                /*User pic*/
                >div:nth-of-type(2) {
                    width: 9vw;
                    height: 9vw;
                    border-radius: 50%;
                    overflow: hidden;

                    >img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        box-shadow: 0.1vw 0.5vw 1vw 0 rgba(0, 0, 0, 0.25);
                    }
                }
            }
        }

        >div {
            width: 100%;
            display: flex;
            justify-content: end;
            align-items: end;
        }
    }
}