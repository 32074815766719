.display-flex-edit-profile {
    width: 90%;
    height: 100%;
    margin-top: 5%;
    display: flex;
    justify-content: end;
    font-family: Jost;
    position: relative;

    .ContainerRightSideEditProfile {
        position: relative;
        width: 50%;
        height: 100%;

        >header {
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;


            >h1 {
                font-weight: 600;
                color: #EEC842;
                font-size: 2.1vw;
            }

            >div {
                position: absolute;
                margin-top: 3%;
                border: 0.3vw solid #fafafa;
                background-color: #fafafa;
                border-radius: 50%;
                box-shadow: 0vw 0vw 0.1vw 0.05vw rgba(0, 0, 0, 0.25);
                right: 0;
                top: 1vw;
                z-index: 1;

                :first-child {
                    width: 6vw;
                    height: 6vw;
                    overflow: hidden;
                    border-radius: 50%;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        cursor: pointer;

                        &:hover {
                            opacity: 70%;
                            transition: opacity 0.3s ease;
                        }
                    }

                }

                input {
                    position: absolute;
                    display: none;
                }
            }
        }


        form {
            margin-top: 6%;
            height: 75%;
            padding: 3vw 3vw;
            box-shadow: 0px 0vw 0.3vw 0.02vw rgba(0, 0, 0, 0.25);
            border-radius: 2vw;
            background-color: #fafafa;
            position: relative;

            >footer {
                width: 100%;
                display: flex;
                align-items: end;
                justify-content: space-between;
                margin-top: 1.5vw;

                >p {
                    font-family: Jost;
                    font-size: 1vw;
                }
            }
        }
    }
}

#logoutButton {
    position: absolute;
    font-size: 0.9vw;
    right: 3vw;
    bottom: 0.5vw;
    opacity: 60%;
    color: red;
    cursor: pointer;
    text-decoration: underline;
    user-select: none;

    &:hover {
        opacity: 100%;
    }
}

#sendMessageToSunnaText {
    display: none;
}


#editProfileLeftStyleImage {
    position: absolute;
    width: 32.5vw;
    left: 0;
    bottom: 5%;
}

.opacity-overlay-edit-profile {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 110vh;
    width: 110vw;
    z-index: 2;
    background-color: rgb(0, 0, 0, 50%);
}

.main-container-confirm-password {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
    height: 16vw;
    width: 25vw;
    background-color: #fafafa;
    border-radius: 2vw;
    box-shadow: 0vw 0vw 0.2vw 0.05vw rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;

    p {
        font-family: Jost;
        width: 80%;
        font-size: 1.6vw;
        text-align: center;
        font-weight: 300;
    }

    input {
        margin-top: -5%;
        font-family: Jost;
        font-size: 1vw;
        border: none;
        border-radius: 0.5vw;
        width: 70%;
        height: 2.4vw;
        background-color: #ffffff;
        padding-left: 0.2vw;
        box-shadow: 0.05vw 0.1vw 0.2vw 0.05vw rgba(0, 0, 0, 0.25);
        text-align: center;

        &:focus {
            outline: none;
        }
    }

    button {
        width: 40%;
    }

}



.input-container-edit-profile {
    margin-top: 2%;
    width: 100%;

    >p {
        font-family: Jost;
        color: #545454;
        font-size: 1vw;
    }

    >input {
        height: 2.6vw;
        font-size: 0.95vw;
        font-family: Jost;
        width: 100%;
        padding-left: 0.2vw;
        color: #6f6f6f;
        border-radius: 0.3vw;
        border: solid 0.1vw #989898;
        box-shadow: 0vw 0vw 0.05vw 0.03vw rgba(0, 0, 0, 0.25);

        &:focus {
            outline: none;
        }
    }

    >div {
        /*seleção de gênero*/
        border: solid 0.1vw #989898;
        box-shadow: 0vw 0vw 0.05vw 0.03vw rgba(0, 0, 0, 0.25);
        width: 100%;
        height: 2.6vw;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #fafafa;
        z-index: 5;

        >p {
            font-family: Jost;
            color: #545454;
            font-size: 1vw;
            margin-left: 2%;
            user-select: none;
        }

        img {
            margin-right: 2%;
        }

        &:hover {
            background-color: #efefef;
            transition: background-color 0.2s ease, border-radius 0.2s ease;
        }
    }
}

.selectionGenderOptions {
    position: absolute;
    right: 0;
    bottom: -3.8vw;
    display: flex;
    flex-direction: column;
    width: 45%;
    border: solid 0.1vw #989898;
    margin-right: -0.1vw;
    box-shadow: 0vw 0vw 0.05vw 0.03vw rgba(0, 0, 0, 0.25);
    border-radius: 0 0 0.3vw 0.3vw;
    background-color: #fafafa;

    >p {
        font-size: 0.9vw;
        font-family: Jost;
        width: 98%;
        margin-left: 2%;
        user-select: none;
        cursor: pointer;

        &:hover {
            background-color: #efefef;
            transition: background-color 0.2s ease, border-radius 0.2s ease;
        }
    }
}

.input-display-flex {
    display: flex;
    position: relative;
    gap: 10%;
}

.button-edit-profile {
    margin-right: -0.5%;
    width: 30%;
    height: 2.5vw;
    font-family: Jost;
    font-size: 1.5vw;
    font-weight: 500;
    color: #fafafa;
    border: none;
    border-radius: 2vw;
    background-color: #EEC842;
    transition: background-color 0.3s ease;
    cursor: pointer;

    &:hover {
        background-color: #C7A630;
        transition: background-color 0.3s ease;
    }
}



@media only screen and (max-width: 768px) {

    #editProfileLeftStyleImage {
        display: none;
    }

    #logoutButton {
        font-size: 3.5vw;
        opacity: 90%;
        bottom: 3.5vw;
    }

    #sendMessageToSunnaText {
        display: block;
        position: absolute;
        font-size: 4.5vw;
        font-weight: 500;
        left: 3vw;
        bottom: 3.5vw;
        color: #EEC842;
        text-decoration: underline;
    }

    .display-flex-edit-profile {
        width: 100%;
        justify-content: center;
        height: 85%;

        .ContainerRightSideEditProfile {
            width: 90%;
            height: 90%;

            >header {
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;


                >h1 {
                    font-weight: 600;
                    color: #EEC842;
                    font-size: 7vw;
                }

                >div {
                    position: absolute;
                    margin-top: 9%;
                    box-shadow: 0vw 0vw 0.3vw 0.1vw rgba(0, 0, 0, 0.25);

                    :first-child {
                        width: 17vw;
                        height: 17vw;

                        img {
                            &:hover {
                                opacity: 100%;
                            }
                        }

                    }
                }
            }

            form {
                margin-top: 10%;
                height: 90%;

                >footer {
                    margin-top: 7.5%;

                    >p {
                        font-family: Jost;
                        font-size: 2.8vw;
                    }

                    .button-edit-profile {
                        width: 40%;
                        font-size: 5vw;
                        height: 9vw;
                        border-radius: 5vw;
                    }
                }
            }
        }
    }


    .opacity-overlay-edit-profile {
        width: 130vw;
    }

    .main-container-confirm-password {
        height: 60vw;
        width: 80%;

        p {
            font-size: 5vw;
        }

        input {
            margin-top: -5%;
            font-size: 3.5vw;
            border-radius: 1vw;
            height: 6vw;
            box-shadow: 0vw 0vw 0.3vw 0.1vw rgba(0, 0, 0, 0.25);

        }

        button {
            width: 50%;
            height: 12%;
            font-size: 5vw;
        }

    }



    .input-container-edit-profile {
        margin-top: 6%;

        >p {
            font-size: 4vw;
        }

        >input {
            height: 8vw;
            border-radius: 1.5vw;
            font-size: 3.3vw;
        }

        >div {
            box-shadow: 0vw 0vw 0.2vw 0.1vw rgba(0, 0, 0, 0.25);
            height: 8vw;
            z-index: 2;

            >p {
                font-family: Jost;
                color: #545454;
                font-size: 3vw;
                margin-left: 2%;
                user-select: none;
            }

            img {
                margin-right: 2%;
                width: 3.5vw;
            }

            &:hover {
                background-color: #efefef;
                transition: background-color 0.2s ease, border-radius 0.2s ease;
            }
        }
    }

    .selectionGenderOptions {
        right: -0.4vw;
        bottom: -13vw;
        width: 45%;
        box-shadow: 0vw 0vw 0.2vw 0.1vw rgba(0, 0, 0, 0.25);
        border-radius: 0 0 1.5vw 1.5vw;

        >p {
            font-size: 3vw;
            font-family: Jost;
            width: 98%;
            margin-left: 2%;
            user-select: none;
            cursor: pointer;

            &:hover {
                background-color: #efefef;
                transition: background-color 0.2s ease, border-radius 0.2s ease;
            }
        }
    }
}