@keyframes shake {

    0%,
    100% {
        transform: translateX(0);
    }

    25% {
        transform: translateX(-2px);
    }

    50% {
        transform: translateX(2px);
    }

    75% {
        transform: translateX(-2px);
    }
}

.edit-categories-open-window-container {
    width: 100%;
    height: 100%;
    position: relative;

    >body {
        display: flex;
        flex-direction: column;
        gap: 2%;
        width: 95%;
        margin-left: 2.5%;
        height: 86%;
        overflow-y: auto;
        padding: 2% 0;
    }

    >footer {
        height: 10%;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: auto;

        button {
            font-family: Jost;
            border: none;
            background-color: #65C37A;
            font-size: 1.6vw;
            font-weight: 600;
            width: 90%;
            height: 2.5vw;
            color: #fafafa;
            border-radius: 0.8vw;
            cursor: pointer;

            &:hover {
                background-color: #4BB763;
                transition: background-color 0.3s ease;
            }
        }
    }
}



.categorie-rendered-in-edit-view {
    width: 90%;
    margin-left: 2.5%;
    font-family: Jost;
    border-radius: 1vw;
    box-shadow: 0 0 0.2vw 0.05vw rgba(0, 0, 0, 0.25);
    padding: 0 2.5%;
    transition: min-height 0.2s ease, background-color 0.3s ease;
    animation: fadeIn 0.3s forwards;


    >div {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        /*img e info*/
        >div {
            width: 70%;
            height: 100%;
            display: flex;
            align-items: center;
            margin-top: 2.5%;

            >div:nth-of-type(1) {
                width: 2.2vw;
                height: 2.2vw;
                border-radius: 100%;
                background-color: blue;
                display: flex;
                align-items: center;
                justify-content: center;

                img {
                    width: 60%;
                    height: 60%;
                }
            }

            /*info*/
            >div:nth-of-type(2) {
                margin-left: 2%;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;

                >p:nth-of-type(1) {
                    font-size: 1.1vw;
                    color: #242424;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: 100%;
                }

                >p:nth-of-type(2) {
                    font-size: 0.9vw;
                    color: #848484;
                }
            }
        }
    }
}

.categorie-rendered-in-edit-selected {
    height: 6.5vw;
    width: 100%;
    display: flex;
    flex-direction: column;
    animation: fadeIn 0.3s forwards;
    padding-bottom: 0.5vw;
    margin-top: 2.5%;

    >body {
        height: 50%;
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-direction: column;

        >div:nth-of-type(1) {
            display: flex;
            align-items: center;

            >label {
                font-family: Jost;
                font-size: 1vw;
                width: 25%;
            }

            >input {
                margin-left: 2%;
                background-color: #fff;
                border-radius: 0.2vw;
                height: 100%;
                border: solid 0.1vw #5A5A5A;
                font-family: Jost;
                font-size: 1vw;
                color: #575757;
                text-indent: 2%;
                width: 74%;

                &:focus {
                    outline: none;
                }
            }
        }

        >div:nth-of-type(2) {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            margin-top: 2%;

            >div:nth-of-type(1) {
                display: flex;
                width: 100%;

                >label {
                    font-family: Jost;
                    font-size: 1vw;
                }
            }
        }
    }

    >footer {
        height: 20%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        /*Confirmar alteração*/
        >p:nth-of-type(1) {
            font-size: 0.9vw;
            color: #65C37A;
            cursor: pointer;

            &:hover {
                text-decoration: underline;
            }
        }

        /*Deletar*/
        >p:nth-of-type(2) {
            font-size: 0.8vw;
            color: #242424;
            cursor: pointer;

            &:hover {
                color: #ED4C5C;
                text-decoration: underline;
            }
        }
    }
}

.color-selector-categorie-edit-window {
    min-height: 100%;
    min-width: 25vw;
    margin-left: 2vw;

    display: flex;
    gap: 3%;
    align-items: center;
    overflow: auto;

    >div {
        min-width: 1.5vw;
        height: 80%;
        border-radius: 0.2vw;
        cursor: pointer;
        transition: box-shadow 0.3s ease;

        &:hover {
            box-shadow: 0 0 0.2vw 0.05vw rgba(0, 0, 0, 0.25);
        }
    }
}

.create-new-categorie-container {
    position: absolute;
    z-index: 7;
    background-color: #fafafa;
    height: 30%;
    width: 100%;
    bottom: 0;
    border-radius: 1vw 1vw 0 0;
    box-shadow: 0 0 0.2vw 0.05vw rgba(0, 0, 0, 0.25);
    animation: fadeIn 0.3s forwards;

    /*selecionar categoria*/
    >header {
        height: 20%;
        width: 95%;
        margin-left: 2.5%;
        border-bottom: 0.1vw solid #D1D1D1;
        display: flex;
        align-items: center;

        >p {
            font-family: Jost;
            font-size: 1.4vw;
        }
    }

    >footer {
        height: 70%;
        width: 95%;
        margin-left: 2.5%;
        border-top: 0.1vw solid #D1D1D1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        label {
            font-family: Jost;
            font-size: 1.2vw;
        }

        input {
            background-color: #fff;
            border-radius: 0.2vw;
            height: 1.5vw;
            border: solid 0.1vw #5A5A5A;
            font-family: Jost;
            font-size: 1.1vw;
            color: #575757;
            text-indent: 2%;
            margin-left: 2.5%;

            &:focus {
                outline: none;
            }
        }

        /*inputs*/
        >div:nth-of-type(1) {
            height: 50%;
            margin-top: 1.5%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            /*inputs - Nome da categirua*/
            >div:nth-of-type(1) {
                display: flex;
                align-items: center;

                label {
                    white-space: nowrap;
                    width: 30%;
                }

                input {
                    width: 70%;
                }

            }

            >div:nth-of-type(2) {
                display: flex;
            }
        }

        /*buttons - create and cancel*/
        >div:nth-of-type(2) {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 20%;

            p {
                font-size: 1vw;
                font-family: Jost;
                color: #242424;
                cursor: pointer;

                &:hover {
                    color: #ED4C5C;
                    text-decoration: underline;
                }
            }

            button {
                font-family: Jost;
                border: none;
                background-color: #65C37A;
                font-size: 1.2vw;
                font-weight: 600;
                width: 30%;
                height: 2vw;
                color: #fafafa;
                border-radius: 0.4vw;
                cursor: pointer;

                &:hover {
                    background-color: #4BB763;
                    transition: background-color 0.3s ease;
                }
            }
        }
    }

}

.creating-new-categorie-overlay {
    position: absolute;
    top: -11.2%;
    height: 100%;
    width: 100%;
    z-index: 6;
    animation: fadeIn 0.3s forwards;
    background-color: rgb(0, 0, 0, 0.4);
}







@media (max-width: 768px) {
    .edit-categories-open-window-container {
        >footer {
            button {
                background-color: #65C37A;
                font-size: 6vw;
                width: 90%;
                height: 12vw;
                border-radius: 2vw;
            }
        }
    }



    .categorie-rendered-in-edit-view {
        width: 90%;

        >div {
            border-radius: 3vw;

            /*img e info*/
            >div {
                width: 100%;

                >div:nth-of-type(1) {
                    width: 8vw;
                    height: 8vw;

                    img {
                        width: 60%;
                        height: 60%;
                    }
                }

                /*info*/
                >div:nth-of-type(2) {

                    >p:nth-of-type(1) {
                        font-size: 4.5vw;
                    }

                    >p:nth-of-type(2) {
                        font-size: 0.9vw;
                        color: #848484;
                    }
                }
            }
        }
    }

    .categorie-rendered-in-edit-selected {
        height: 70%;

        >body {
            height: 65%;
            >div:nth-of-type(1) {
                display: flex;
                flex-direction: column;
                align-items: start;

                >label {
                    font-family: Jost;
                    font-size: 4.3vw;
                    width: 100%;
                }

                >input {
                    margin-left: 0%;
                    border-radius: 1vw;
                    height: 6vw;
                    font-size: 4.3vw;
                    width: 100%;
                }
            }

            >div:nth-of-type(2) {

                >div:nth-of-type(1) {
                    display: flex;
                    width: 100%;

                    >label {
                        font-family: Jost;
                        font-size: 4.3vw;
                    }
                }
            }
        }

        >footer {

            /*Confirmar alteração*/
            >p:nth-of-type(1) {
                font-size: 4vw;
            }

            /*Deletar*/
            >p:nth-of-type(2) {
                font-size: 4vw;
            }
        }
    }

    .color-selector-categorie-edit-window {
        min-width: 50vw;

        >div {
            min-width: 5.1vw;
            border-radius: 1vw;
        }
    }

    .create-new-categorie-container {
        height: 30%;

        /*selecionar categoria*/
        >header {
            >p {
                font-size: 5.5vw;
                font-weight: 500;
            }
        }

        >footer {
            label {
                font-family: Jost;
                font-size: 4.5vw;
            }

            input {
                border-radius: 1vw;
                height: 6vw;
                font-size: 4.3vw;
            }

            /*inputs*/
            >div:nth-of-type(1) {
                height: 45%;

                /*inputs - Nome da categirua*/
                >div:nth-of-type(1) {
                    display: flex;
                    align-items: center;

                    label {
                        white-space: nowrap;
                        width: 40%;
                    }

                    input {
                        width: 60%;
                    }

                }

                >div:nth-of-type(2) {
                    display: flex;

                    /*color selector*/
                    >div{
                        width: 60vw;
                    }
                }
            }

            /*buttons - create and cancel*/
            >div:nth-of-type(2) {
                display: flex;
                justify-content: space-between;
                align-items: center;
                height: 20%;

                p {
                    font-size: 4vw;
                }

                button {
                    font-size: 5vw;
                    font-weight: 600;
                    width: 50%;
                    margin-right: 2%;
                    height: 8vw;
                    border-radius: 2vw;
                    cursor: pointer;
                }
            }
        }

    }
}