.mainContainerSectionFour {
    width: 100%;
    height: 55vw;
    background-color: #242424;
    position: relative;
    margin-top: 5%;
    border-bottom: #EEC842 solid 1vw;
    


    >div:nth-of-type(1) {
        position: absolute;
        background-color: #242424;
        height: 10vw;
        width: 120%;
        transform: rotate(-2deg);
        top: -2.5vw;
        left: -1vw;
        z-index: -1;
    }

    header {
        height: fit-content;
        width: 100%;
        padding-top: 5%;

        p {
            text-align: center;
            color: #fafafa;
            font-family: Jost;
            margin-bottom: 1%;
        }

        #titleSectionFourLP {
            font-size: 3.5vw;
            font-weight: 600;
        }

        #textSectionFourLP {
            margin-left: 21.25%;
            width: 57.5%;
            font-size: 1.2vw;
            font-weight: 300;
        }

        div {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            height: fit-content;

            button {
                margin-top: 1%;
                width: 15vw;
                height: 3vw;
                font-family: Jost;
                border: none;
                border-radius: 0.3vw;
                font-size: 1.3vw;
                font-weight: 600;
                cursor: pointer;
                color: #EEC842;
                background-color: transparent;
                border: #EEC842 solid 0.15vw;
                transition: background-color 0.5s, color 0.5s, border 0.5s;

                &:hover {
                    color: #fafafa;
                    background-color: #EEC842;
                    border: none;
                    transition: background-color 0.5s, color 0.5s, border 0.5s;
                }
            }
        }
    }
}

.bodySectionFourImage {
    width: 100%;
    height: max-content;
    display: flex;
    align-items: bottom;
    justify-content: center;

    img {
        width: 89.7vw;
    }
}

@media only screen and (max-width: 768px) {
    .mainContainerSectionFour {
        height: 115vw;
        overflow: hidden;
        header {

            #titleSectionFourLP {
                font-size: 5vw;
                margin-left: 5%;
                width: 90%;
            }

            #textSectionFourLP {
                width: 90%;
                margin-left: 5%;
                text-align: justify;
                margin-top: 5%;
                font-size: 4vw;
                text-align: center;
            }

            div {
                button {
                    width: 40vw;
                    height: 10vw;
                    font-size: 4vw;
                    margin-top: 5%;
                }
            }
        }
    }
    .bodySectionFourImage {
        img {
            margin-top: 5%;
            height: 40%;
        }
    
    }
}