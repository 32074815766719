.Section5FormAboutUs {
    margin-top: 1.5vw;

    form {
        border-radius: 0.8vw;
        display: flex;
        flex-direction: column;
        gap: 1.1vw;
        background-color: white;
        padding: 3vw 5vw;
    }

    input,
    textarea {
        width: 30vw;
        border: 0.1vw solid #575757;
        padding-left: 1vw;
        height: 3vw;
        border-radius: 0.5vw;
        font-family: Jost;
        color: #575757;
        font-size: 1.1vw;
    }

    textarea {
        width: 29vw;
        resize: none;
        padding-bottom: 7vw;
        padding-top: 0.8vw;
        padding-right: 1vw;
    }

    input::placeholder,
    textarea::placeholder {
        font-family: Jost;
        font-weight: 500;
        font-size: 1.1vw;
    }

    input:focus,
    textarea:focus {
        border: 0.1vw solid #EEC842;
        outline: none;
    }

    div {
        display: flex;
        justify-content: right;
        align-items: center;

        button {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 0.8vw;
            font-family: Jost;
            width: 10vw;
            background-color: #EEC842;
            box-shadow: 0.1vw 0.2vw 0.2vw rgba(0, 0, 0, 0.2);
            border: none;
            border-radius: 1vw;
            padding: 0.7vw;
            color: white;
            font-size: 1.1vw;
            width: 12vw;
            cursor: pointer;
            transition: 0.3s;
    
            &:hover{
                background-color: #C7A630;
                transition: 0.8s;
                transform: scale(1.07);
            }
        }

        img {
            height: 1.5vh;
        }
    }
}


/* Tablet or small screens settings */
@media only screen and (max-width: 1000px) {
    .Section5FormAboutUs {
        margin-top: 1.5vw;
    

    
    }
}

/* Mobile settings */
@media only screen and (max-width: 768px) {
    .Section5FormAboutUs {
        height: 70vw;
        display: flex;
        flex-direction: column;
        width: 80%;
        margin-top: 10%;
    
        form {
            display: flex;
            flex-direction: column;
            gap: 2vw;
            height: 80%;
        }
    
        input,
        textarea {
            width: 70vw;
            height: 8vw;
            font-size: 2vw;
        }
    
        textarea {
            width: 69vw;
            height: 20vw;
        }
    
        input::placeholder,
        textarea::placeholder {
            font-weight: 500;
            font-size: 3vw;
        }
    
    
        div {
            margin-right: -1vw;

            button {
                width: 35vw;
                font-size: 4vw;
                transition: 0.3s;

            }
    
        }
    }    
}