    .OpacityOverlay {
        background-color: #24242480;
        position: absolute;
        width: 100%;
        height: 100%;
        bottom: 0;
        z-index: 4;
        left: 0;
        animation: fadeIn 0.5s forwards;
    }

    .CreateNewFinanceWindow {
        background-color: #fafafa;
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 0;
        z-index: 5;
        transition: height 0.3s ease;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        overflow: hidden;
        gap: 0.8vw;
        height: auto;
        padding: 1vw 0;

        >div {
            width: 80%;
            border-radius: 0.5vw;

            >div {
                /*Button nova receita/gasto*/
                font-family: Jost;

                align-items: center;
                justify-content: space-between;

                border: solid 1px;
                min-height: 2.7vw;
                padding: 0 0.7vw;
                cursor: pointer;
                border-radius: 0.5vw;
                display: flex;

                >p {
                    width: fit-content;
                    font-size: 1vw;
                }

                >div {
                    /*Icone de receita/gasto*/
                    width: 1.7vw;
                    height: 1.7vw;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    img {
                        width: 60%;
                        height: 60%;
                    }

                }
            }
        }

    }


    @media only screen and (max-width: 768px) {
        .OpacityOverlay {
            background-color: #24242480;
            position: fixed;
            width: 100%;
            height: 210vh;
            top: 0;
            z-index: 500;
            left: 0;
            animation: fadeIn 0.5s forwards;
        }

        .CreateNewFinanceWindow {
            position: fixed;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-evenly;
            overflow: hidden;
            gap: 0.8vw;
            padding: 0;
            border-radius: 3vw 3vw 0 0;
            z-index: 501;
    
            >div {
                width: 90%;
                border-radius: 3vw;
    
                >div {
                    /*Button nova receita/gasto*/
                    align-items: center;
                    justify-content: space-between;
    
                    border: solid 1px;
                    cursor: pointer;
                    border: solid 0.1vw;
                    border-radius: 1vw;
                    display: flex;
                    padding: 2vw 0;
    
                    >p {
                        width: fit-content;
                        font-size: 5vw;
                        margin-left: 3%;
                    }
    
                    >div {
                        /*Icone de receita/gasto*/
                        width: 7vw;
                        height: 7vw;
                        border-radius: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-right: 3%;

                        img {
                            width: 60%;
                            height: 60%;
                        }
    
                    }
                }
            }
    
        }

    }