.containerDashboard {
    height: calc(var(--vh, 1vh) * 100);
    padding-top: env(safe-area-inset-top);
    padding-bottom: env(safe-area-inset-bottom);
    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);
    display: flex;
    background-color: #f9f9f9;
    position: relative;

    >aside {
        position: fixed;
        width: 11%;
        transition: width 0.5s;
    }

    >div:nth-of-type(1) {
        margin-left: 11%;
        width: 89%;
        padding-bottom: 5%;
        height: 89.8vh;

        /* Degradê suave */
        >header {
            margin-left: 3%;
            z-index: 1;
            width: 93%;
            height: 7vh;
        }

        >section {
            margin-left: 4%;
            width: 92%;
            height: 96%;
        }
    }
}




.FinancialCardSummaryContainer {
    width: 100%;
    height: 100%;
    background-color: #fff;
    border-radius: 0.2vw;
    box-shadow: 0 0 0.2vw 0.04vw rgba(0, 0, 0, 0.25);
    transition: height 0.3s, width 0.3s;
    cursor: default;
}

@media (max-width: 768px) {
    .containerDashboard {
        overflow: hidden;

        .sideBarSmartPhone {
            position: fixed;
            z-index: 1;
            width: 91.5%;
            bottom: 2%;
            margin-left: 4%;
            z-index: 3;
        }

        >div:nth-of-type(1) {
            width: 100%;
            margin-left: 0%;

            >header {
                position: fixed;
                z-index: 1;
                top: 0;
                width: 100%;
                margin-left: 0%;
                height: fit-content;
                height: 13vh;
                background-color: #fafafa;
            }

            >section {
                margin-left: 0%;
                height: 100vh;
                width: 100%;
            }

        }
    }

    .spaceSmartPhoneBottomBar {
        width: 100%;
        height: 20vw;
    }

    .FinancialCardSummaryContainer {
        border-radius: 1.5vw;
        box-shadow: 0 0 0.8vw 0.3vw rgba(0, 0, 0, 0.25);
        transition: height 0.3s, width 0.3s;
        cursor: default;
    }
}