.mainContainerInputSingIn {
    align-items: center;
    margin-bottom: 1.2vw;
    margin-top: 0.4vw;
    position: relative;

    label {
        user-select: none;
        cursor: text;
        z-index: 1;
        position: absolute;
        width: fit-content;
        height: fit-content;
        font-size: 1vw;
        font-family: Jost;
        margin-left: 3%;
        color: #575757;
        font-weight: 500;
        margin-top: -2.5%;
        background-color: #ffffff;
        padding: 0 0.5% 0 0.5%;
    }
}


.containerInputSingIn {
    width: 100%;
    height: 3vw;
    border-radius: 0.5vw;
    border: #575757 solid 0.1vw;
    transition: border 0.4s ease;
    display: flex;

    input {
        width: 98%;
        height: 100%;
        border: none;
        border-radius: 0.7vw;
        padding-left: 2%;
        font-size: 1vw;
        font-family: Jost;

        &:focus {
            outline: none;
        }
    }
}


.containerInputSingIn.active {
    border: #EEC842 solid 0.1vw;
    transition: border 0.3s ease;
}


input:-moz-autofill {
    box-shadow: 0 0 0 1000px white inset !important;
}

input:-webkit-autofill {
    box-shadow: 0 0 0 1000px white inset !important;
}

.AlertInputSingIn {
    margin-left: 2%;
    margin-top: 1%;
    font-size: 0.8vw;
    font-family: Jost;
    color: #575757;
}

.errorMessageLogin {
    color: red;
    max-width: 90%;
}

@media only screen and (max-width: 768px) {

    .mainContainerInputSingIn {
        margin-bottom: 8vw;
        margin-top: 5vw;
    
        label {
            z-index: 1;
            font-size: 4.5vw;
            margin-top: -4%;
        }
    }
    
    
    .containerInputSingIn {
        height: 11vw;
        border-radius: 1.1vw;

        input {
            font-size: 4.3vw;
            font-family: Jost;
    
        }
    }
    
    input:-moz-autofill {
        box-shadow: 0 0 0 1000px white inset !important;
    }
    
    input:-webkit-autofill {
        box-shadow: 0 0 0 1000px white inset !important;
    }
    
    .AlertInputSingIn {
        font-size: 3vw;
        font-family: Jost;
    }
    
    .errorMessageLogin {
        color: red;
        font-size: 3vw;
        width: 85%;
    }
    
}