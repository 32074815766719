.mainContainerSectionThree {
    height: 65%;
    width: 100%;
    display: flex;
    align-items: center;

    >div:nth-of-type(1) {
        width: 45%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        div {
            height: 70%;
            width: 75%;

            p {
                font-family: Jost;
                color: #383227;
                margin-top: 3%;
            }

            button {
                margin-top: 5%;
                border: #EEC842 solid 0.2vw;
                border-radius: 0.2vw;
                background-color: #fafafa;
                color: #EEC842;
                font-weight: 500;
                width: 55%;
                height: 12%;
                font-size: 1.2vw;
                cursor: pointer;
                transition: color 0.5s, background-color 0.5s;

                &:hover {
                    color: #fafafa;
                    background-color: #EEC842;
                    transition: color 0.5s, background-color 0.5s;
                }
            }

            #titleSectionThree {
                font-weight: 600;
                font-size: 2.5vw;
            }

            #subtitleSectionThree {
                font-size: 1.4vw;
            }
        }
    }


    >div:nth-of-type(2) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, auto);
        gap: 5%;
        width: 55%;
        height: 80%;
        margin-bottom: 10%;
        padding-right: 5%;
    }
}

.mainContainerCardSectionThree {
    width: 100%;
    margin-bottom: 5%;
    margin-right: 5%;

    header {
        margin-bottom: 2%;

        img {
            margin-bottom: 2%;
            max-width: 2vw;
            user-select: none;
        }

        div {
            display: flex;

            div {
                height: 2vw;
                width: 0.2vw;
                background-color: #EEC842;
                user-select: none;
            }

            p {
                font-family: Jost;
                margin-left: 3%;
                font-size: 1.5vw;
                font-weight: 500;
            }
        }
    }

    >div:nth-of-type(1) {
        p {
            margin-left: 3.5%;
            font-family: Jost;
            font-size: 1.2vw;
            text-align: justify;
        }
    }

}

@media only screen and (max-width: 768px) {
    .mainContainerSectionThree {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: auto;
        margin-top: 22.5%;

        >div:nth-of-type(1) {
            width: 80%;

            div {
                width: 95%;

                p {
                    margin-top: 5%;
                }

                button {
                    margin-top: 5%;
                    margin-left: 15%;
                    width: 70%;
                    height: 8vw;
                    font-weight: 600;
                    font-size: 5.5vw;
                }

                #titleSectionThree {
                    font-weight: 600;
                    font-size: 6vw;
                    text-align: left;
                }

                #subtitleSectionThree {
                    font-size: 4.5vw;
                }
            }
        }


        >div:nth-of-type(2) {
            display: block;
            width: 65%;
            height: 80%;
            margin-top: 10%;
        }
    }

    .mainContainerCardSectionThree {
        margin-top: 10%;

        header {
            display: flex;
            align-items: center;

            img {
                max-width: 7vw;
                user-select: none;
            }

            div {
                display: flex;
                align-items: center;
                margin-left: 2%;
                width: 90%;

                div {
                    height: 8vw;
                    width: 0.5vw;
                    background-color: #EEC842;
                    user-select: none;
                }

                p {
                    margin-left: 3%;
                    width: fit-content;
                    font-family: Jost;
                    font-size: 6vw;
                    white-space: nowrap;
                    font-weight: 500;
                }
            }
        }

        >div:nth-of-type(1) {
            p {
                font-family: Jost;
                font-size: 4vw;
                text-align: justify;
            }
        }

    }
}