@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.creditCardContainer {
    height: 100%;

    >header {
        display: flex;
        height: 20%;
        width: 95%;
        margin-left: 2.5%;
        align-items: center;
        justify-content: space-between;

        >p {
            font-family: Jost;
            font-size: 1.25vw;
        }
    }

    /*line*/
    >div:nth-of-type(1) {
        height: 1px;
        width: 100%;
        background-color: #D1D1D1;
    }

    >body {
        max-height: 75%;
        overflow-y: auto;
    }
}

@media (max-width: 768px) {

    .creditCardContainer {
        height: 100%;

        >header {
            >p {
                font-size: 5.7vw;
            }
        }

        >body {
            max-height: 75%;
            overflow-y: auto;
        }
    }
}