.editGenericButtonComponent {
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-color: transparent;
    border: none;
    width: 4.1vw;
    cursor: pointer;
    height: 2vw;

    p {
        font-family: Comfortaa;
        font-size: 0.8vw;
        color: #595959;
    }

    img {
        width: 0.9vw;
    }
}

@media (max-width: 768px) {
    .editGenericButtonComponent {
        width: 17vw;
        cursor: pointer;
        height: 2vw;

        p {
            font-size: 3.3vw;
            color: #595959;
        }

        img {
            width: 3vw;
        }
    }
}