.politica-de-privacidade-container {
    width: 100%;
    overflow-x: hidden;
    height: 100vh;
    padding-top: env(safe-area-inset-top);
    padding-bottom: env(safe-area-inset-bottom);
    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);
}

.container-info-privacy-policy {
    width: 90%;
    margin-left: 5%;
    margin-top: 3%;
    font-family: Jost;
    color: #242424;
    display: flex;
    flex-direction: column;
    gap: 1.5vw;
    padding-bottom: 3vw;

    >h1 {
        font-size: 2em;
    }

    >div {
        margin-left: 2%;

        h2{
            font-size: 1.5em;
        }


        >p {
            color: #555555;
            font-size: 1em;
        }

        >ul {
            color: #555555;
            margin-left: 2%;
            font-size: 1em;
        }
    }

}