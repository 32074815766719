@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.containerSideBar {
    background-color: #242424;
    height: 100vh;
    position: relative;

    >img:nth-of-type(1) {
        margin-top: 10%;
        margin-left: 10%;
        width: 8.5vw;
        user-select: none;
        cursor: pointer;
    }

    >img:nth-of-type(2) {
        display: none;
        cursor: pointer;
    }




    ul {
        margin-top: 45%;
        height: 75vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;

        >div {
            width: 100%;
            height: 65%;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }

    li {
        width: 70%;
        height: 6vw;
        margin-top: 5%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        user-select: none;
        border-radius: 0.5vw;
        transition: background-color 0.4s ease;

        &:hover {
            background-color: rgba(217, 217, 217, 0.1);
            transition: background-color 0.4s ease;
        }

        img {
            margin-right: 10%;
            margin-left: 10%;
            width: 1.3vw;
            user-select: none;
        }
    }

    li.active {
        background-color: rgba(217, 217, 217, 0.1);
    }

    p {
        color: #EEC842;
        font-family: Jost;
        font-size: 1vw;
        font-style: normal;
        font-weight: 600;
    }
}

@media (max-width: 768px) {

    .containerBottomBarSmartphone {
        background-color: #fafafa;
        height: 10vh;
        position: relative;
        display: block;
        border-radius: 2vw;
        box-shadow: 0px 0vw 1vw 0.2vw rgba(0, 0, 0, 0.25);

        >ul {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: space-around;
            list-style: none;

            >li {
                width: 15%;
                height: 85%;

                >p {
                    text-align: center;
                    font-size: 3vw;
                    color: #606060;
                    font-family: Jost;
                    font-weight: 600;
                    margin-top: -3%;
                }

                >div {
                    box-shadow: 0px 0vw 2vw 0.1vw rgba(0, 0, 0, 0.25);
                    border-radius: 2vw;
                    height: 70%;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    img {
                        width: 35%;
                        user-select: none;
                    }
                }
            }

            >li:nth-of-type(4) {
                >div {
                    background-color: #EEC842;
                }
            }
        }
    }
}

