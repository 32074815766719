.containerUserBalance {
    display: flex;
    flex-direction: column;

    /*seu saldo e botão de alteração*/
    >div:nth-of-type(1) {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 3%;

        p:nth-of-type(1) {
            font-family: Jost;
            font-size: 1.2vw;
        }

        img {
            cursor: pointer;
            width: 0.9vw;
        }
    }

    /*balance and hide*/
    >div:nth-of-type(2) {
        display: flex;
        align-items: center;
        justify-content: space-between;

        >p {
            font-size: 1.6vw;
            font-family: Jost;

            >b {
                font-weight: 500;
            }
        }

        >img {
            width: 1.7vw;
            cursor: pointer;
        }
    }

    /*grey line*/
    >div:nth-of-type(3) {
        background-color: #D9D9D9;
        height: 0.1vw;
        width: 100%;
        margin: 1.5% 0;
    }


    /*expected balance*/
    >div:nth-of-type(4) {
        display: flex;
        align-items: center;
        justify-content: space-between;

        >p:nth-of-type(1) {
            font-family: Comfortaa;
            font-size: 0.85vw;
        }

        >p:nth-of-type(2) {
            font-family: Jost;
            font-size: 0.85vw;

            >b {
                font-weight: 500;
            }
        }

    }

}



@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.changingBalanceModelOverlay {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 4;
    animation: fadeIn 0.3s forwards;

    /*window para selecionar*/
    >div {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 30vw;
        height: 17.5vw;
        background-color: #fafafa;
        font-family: Jost;
        border-radius: 0.75vw;

        >header {
            height: 20%;
            border-bottom: #CCCCCC solid 1px;
            justify-content: center;
            display: flex;
            flex-direction: column;

            >h1 {
                font-size: 1.1vw;
                font-weight: 600;
                margin-left: 2.5%;
            }

            >h2 {
                font-size: 0.9vw;
                font-weight: 400;
                color: #5B5B5B;
                margin-left: 2.5%;
            }
        }


        >body {
            height: 52%;
            width: 95%;
            margin-left: 2.5%;
            margin-top: 5%;

            /*Botões*/
            >div:nth-of-type(1) {
                height: 40%;
                display: flex;
                justify-content: space-between;

                button {
                    width: 48.5%;
                    background-color: transparent;
                    border: solid 1px #CCCCCC;
                    border-radius: 0.3vw;
                    display: flex;
                    align-items: center;
                    padding: 0 3%;
                    height: 2.5vw;
                    font-family: Jost;
                    font-size: 0.85vw;
                    white-space: nowrap;

                    >p {
                        margin-left: 3%;
                    }

                    img {
                        width: 1vw;
                    }

                    &:hover {
                        background-color: rgba(0, 0, 0, 0.1);
                        transition: background-color 0.3s ease;
                        cursor: pointer;
                    }

                }
            }

            /*Explicação*/
            >div:nth-of-type(2) {
                height: 58%;
                margin-top: 2%;

                >p:nth-of-type(1) {
                    font-weight: 500;
                    font-size: 1.05vw;
                }

                >p:nth-of-type(2) {
                    font-size: 0.85vw;
                    text-align: justify;

                    span {
                        font-weight: 400;
                        color: red;
                    }

                    b {
                        font-weight: 600;
                        color: #000000;
                    }
                }
            }
        }


        >footer {
            height: 20%;
            display: flex;
            align-items: center;
            justify-content: end;
            width: 95%;

            button {
                background-color: #EEC842;
                border-radius: 0.6vw;
                font-family: Jost;
                font-size: 1.3vw;
                font-weight: 600;
                color: #fafafa;
                width: 25%;
                height: 2vw;
                border: none;
                cursor: pointer;
                transition: background-color 0.3s ease;

                &:hover {
                    background-color: #C7A630;
                }
            }
        }
    }
}


@media only screen and (max-width: 768px) {

    .smartphoneTotalCard {
        display: block;
        width: 95%;
        margin-left: 2.5%;
        cursor: default;
        transition: height 0.3s ease;

        >div:nth-of-type(1) {
            /*saldo do mes*/
            display: flex;
            flex-direction: column;
            font-family: Jost;

            >div:nth-of-type(1) {
                /*saldo do mes texto*/
                display: flex;

                p {
                    font-size: 4.3vw;
                    color: #383227;
                    font-weight: 500;
                }

                img {
                    width: 3.5vw;
                    margin-left: 3vw;
                }
            }

            >div:nth-of-type(2) {
                /*saldo do mes valor e seta*/
                display: flex;
                align-items: baseline;
                width: 100%;
                justify-content: space-between;
                padding-bottom: 1vw;
                border-bottom: solid 0.1vw #D9D9D9;

                >p {
                    font-size: 6.6vw;
                    font-weight: 500;
                    color: #242424;
                }
            }
        }

        >div:nth-of-type(2) {
            display: flex;
            justify-content: space-between;
            margin-top: 1vw;
            font-size: 4.2vw;

            >p:nth-of-type(1) {
                font-family: Comfortaa;
            }
            >p:nth-of-type(2) {
                font-family: Jost;
            }
        }
    }




    .changingBalanceModelOverlay {
        /*window para selecionar*/
        >div {
            width: 90vw;
            height: 120vw;
            background-color: #fafafa;
            border-radius: 3vw;
    
            >header {
                height: 20%;
                border-bottom: #CCCCCC solid 1px;
                justify-content: center;
                display: flex;
                flex-direction: column;
                margin-left: 2.5%;
                width: 95%;
    
                >h1 {
                    font-size: 5.5vw;
                    font-weight: 600;
                }
    
                >h2 {
                    font-size: 3.6vw;
                    font-weight: 400;
                }
            }
    
    
            >body {
                height: 65%;
                margin-left: 2.5%;
                margin-top: 3vw;
                /*Botões*/
                >div:nth-of-type(1) {
                    height: 50%;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-evenly;
                    
                    button {
                        width: 100%;
                        border: solid 1px #CCCCCC;
                        border-radius: 2vw;
                        height: 13vw;
                        font-family: Jost;
                        font-size: 4.2vw;
    
                        >p {
                            margin-left: 3%;
                            color: #242424;
                        }
    
                        img {
                            width: 4vw;
                        }
    
                        &:hover {
                            background-color: rgba(0, 0, 0, 0.1);
                            transition: background-color 0.3s ease;
                            cursor: pointer;
                        }
    
                    }
                }
    
                /*Explicação*/
                >div:nth-of-type(2) {
                    height: 50%;
                    margin-top: 5%;
    
                    >p:nth-of-type(1) {
                        font-weight: 500;
                        font-size: 5.2vw;
                    }
    
                    >p:nth-of-type(2) {
                        margin-top: 2%;
                        font-size: 3.7vw;
                    }
                }
            }
    
    
            >footer {
                height: 15%;
                display: flex;
                align-items: start;
                justify-content: end;
                width: 97.5%;
    
                button {
                    background-color: #EEC842;
                    border-radius: 1vw;
                    font-size: 4vw;
                    width: 45%;
                    height: 9vw;
                }
            }
        }
    }
}