.Section2AboutUs {
    position: relative;
    background-color: #242424;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 5%;
    height: 8vw;

    img {
        position: absolute;
        width: 8vw;
        right: 0%;
        bottom: 12vw;
    }

    h1 {
        font-family: Jost;
        font-size: 2.2vw;
        color: #FFFFFF;
        text-align: left;
        max-width: 30%;
    }

    h2 {
        font-family: Jost;
        font-weight: 400;
        text-align: right;
        max-width: 30%;
        font-size: 1.1vw;
        color: #FFFFFF;
    }
    
}

/* Tablet or small screens settings */
@media only screen and (max-width: 1000px) {
    .Section2AboutUs {
        background-color: #242424;
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding: 5%;
        position: relative;
    
        img {
            position: absolute;
            width: 8vw;
            right: 0%;
            bottom: 12vw;
        }
    
        h1 {
            font-size: 2.5vw;
            color: #FFFFFF;
            max-width: 40%;
        }
    
        h2 {
            max-width: 40%;
            font-size: 1.7vw;
            color: #FFFFFF;
        }
        
    }    
}

/* Mobile settings */
@media only screen and (max-width: 768px) {
    .Section2AboutUs {
        height: 20vw;
        img {
            position: absolute;
            width: 15vw;
            right: 0%;
            bottom: 23vw;
        }
    
        h1 {
            font-size: 4.5vw;
            color: #FFFFFF;
            max-width: 50%;
        }
    
        h2 {
            max-width: 45%;
            font-size: 3vw;
            color: #FFFFFF;
            text-align: justify;
        }
        
    }
}