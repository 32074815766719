.mainFinanceCardOpenViewShadow {
    height: 12.5vw;
    width: 87%;
    margin-bottom: 0.8vw;
    transition: height 0.3s, width 0.3s;
}

.openFinancialCardEdit {
    width: 93%;
    margin-top: 0;
    height: 25.7vw;
}

.fullSizeWidthHeight {
    width: 100%;
    height: 100%;
}

.headerShortFinancialCardView {
    height: 20%;
    display: flex;

    >div {
        margin-top: 2.5%;
    }

    >div:nth-of-type(1) {
        width: 80%;
        display: flex;
        flex-direction: column;
        gap: 0.2vw;
    }

    >div:nth-of-type(2) {
        margin-right: 2.5%;
        width: 15%;
        height: 41%;
        display: flex;
        align-items: center;
        justify-content: center;

        >img {
            border-radius: 10%;
            width: 1.3vw;
            height: 1.3vw;
        }
    }
}

.HeaderNoteFinancialCardView {
    font-family: Jost;
    font-size: 0.6vw;
    border-radius: 1vw;
    width: fit-content;
    max-width: 77.5%;
    margin-left: 5%;

    >p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 0 0.3vw;
    }

}


.mainContainerFinanancialCardInfoView {
    width: 100%;
    height: 100%;
    background-color: #fff;
    border-radius: 0.1vw;
    box-shadow: 0 0 0.3vw 0.02vw rgba(0, 0, 0, 0.25);
    transition: height 0.3s, width 0.3s;
    cursor: pointer;
    margin-top: 0;

    section {
        height: 50%;
        margin-top: 5%;
        display: flex;
        flex-direction: column;

    }
}

.BodyFinancialCardOpenView {
    margin-left: 5%;
    font-family: Jost;
}

#TitleFinancialCardOpenView {
    font-size: 1vw;
    font-weight: 500;
    letter-spacing: -0.02vw;
    max-width: 9.5vw;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

#DateFinancialCardOpenView {
    margin-top: -2%;
    font-size: 0.7vw;
    color: #505050;

}

#RecurrenceWithDateFinancialCard {
    font-family: Jost;
    font-size: 0.8vw;
    width: 90%;
    margin-left: 5%;
    margin-top: 3%;
    text-align: right;
}

.ContainerPaidReceived {
    width: 85%;
    margin-left: 10%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: Jost;
    height: 1.5vw;

    p {
        font-size: 0.85vw;
        font-family: Jost;
    }

    >div {
        /* checkboxdiv */
        padding: 0.4vw;
        border-radius: 50%;
        cursor: pointer;

        &:hover {
            background-color: #2890ff33;
        }

        >input {
            width: 1vw;
            height: 1vw;
            border: 0.1vw solid #b6b6b6;
            box-shadow: 0px 0vw 0.15vw 0.01vw rgba(0, 0, 0, 0.25);
            border-radius: 0.2vw;
        }

        >input::after {
            border-width: 0 0.15vw 0.15vw 0;
            width: 0.3vw;
            height: 0.6vw;
            left: 0.01vw;
            top: -0.1vw;
        }
    }
}

.mainContainerFinanancialCardInfoView footer {
    width: 100%;
    border-top: solid 0.1vw #dadada;
    margin-top: 10%;
    font-size: 1.1vw;
    font-weight: 500;
    height: 20%;
    font-family: Jost;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
}

.OpenFinancialCardHeaderEdit {
    display: flex;
    align-items: start;
    justify-content: space-between;
    height: 20%;
    width: 90%;
    margin-left: 5%;

    >div {
        margin-top: 2%;
    }

    >img {
        width: 1.5vw;
        margin-top: 2%;
    }
}

.HeaderNoteFinancialCardEdit {
    font-family: Jost;
    font-size: 0.8vw;
    border-radius: 1vw;
    width: max-content;
    max-width: 11vw;
    margin-top: 3%;
    height: 1.2vw;
    display: flex;
    align-items: center;
    padding: 0 0.45vw;

    >img {
        max-width: 1vw;
        max-height: 1vw;
        margin-right: 0.3vw;
    }

    /*categoria*/
    div {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 1vw;
        width: 1vw;
        border-radius: 10%;
        margin-right: 0.3vw;

        img {
            width: 0.8vw;
            height: 0.8vw;
        }
    }

    p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-right: -0vw;
        height: fit-content;
    }
}



.cursorDefault {
    cursor: default;
}

#TitleFinancialCardOpenEdit {
    font-size: 1.3vw;
    font-weight: 500;
    letter-spacing: -0.03vw;
    max-width: 20vw;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

#DateFinancialCardOpenEdit {
    margin-top: -2%;
    font-size: 1vw;
}

#CreatedDateFinancialCardOpenEdit {
    margin-top: -1%;
    font-size: 0.7vw;
    color: #727272;
}

#ObservationsTitleFinancialCardOpenEdit {
    margin-top: 6%;
    font-size: 1.1vw;
    font-weight: 500;
}

#ObservationsFinancialCardOpenEdit {
    font-size: 0.9vw;
    max-width: 80%;
    height: 4vw;
    max-height: 5vw;
    padding-right: 2%;
    overflow: auto;
    word-wrap: break-word;
    overflow-wrap: break-word;
}

#RecurrenceWithDateFinancialCardEdit {
    font-family: Jost;
    font-size: 1vw;
    width: 90%;
    margin-left: 5%;
    text-align: right;
}

.ContainerPaidReceivedEdit {
    width: 87.5%;
    margin-left: 7.5%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
        font-size: 1.15vw;
        font-family: Jost;
    }

    >div {
        /* checkboxdiv */
        padding: 0.5vw;
        border-radius: 50%;
        cursor: pointer;

        &:hover {
            background-color: #2890ff33;
        }

        >input {
            width: 1.3vw;
            height: 1.3vw;
            border: 0.15vw solid #b6b6b6;
            box-shadow: 0px 0vw 0.2vw 0.015vw rgba(0, 0, 0, 0.25);
            border-radius: 0.3vw;
        }

        >input::after {
            border-width: 0 0.15vw 0.15vw 0;
            width: 0.5vw;
            height: 0.9vw;
            left: 0.01vw;
            top: -0.15vw;
        }
    }
}

.mainContainerFinanancialCardInfoEdit {

    background-color: #fff;
    border-radius: 0.1vw;
    box-shadow: 0 0 0.3vw 0.02vw rgba(0, 0, 0, 0.25);
    transition: height 0.3s, width 0.3s;
    height: 100%;

    section {
        margin-top: 5%;
        height: 60%;
    }

    footer {
        margin-top: 5%;
        height: 13.5%;
        width: 100%;
        border-radius: 0 0 0.1vw 0.1vw;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: Jost;
        font-size: 1.9vw;
        color: #fafafa;
        font-weight: 500;
        box-shadow: 0px 0vw 0.3vw 0.02vw rgba(0, 0, 0, 0.25);

    }
}

/*Checkbox design*/

.custom-checkbox {
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 1vw;
    height: 1vw;
    border: 0.1vw solid #b6b6b6;
    box-shadow: 0px 0vw 0.15vw 0.01vw rgba(0, 0, 0, 0.25);
    border-radius: 0.2vw;
    outline: none;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.custom-checkbox:checked {
    background-color: #2890ff;
    border-color: #2890ff;
}

.custom-checkbox::after {
    content: '';
    display: inline-block;
    border: solid #fafafa;
    transform: rotate(45deg) !important;
    position: relative;
    transform: translate(-50%, -50%) scale(0);
    transition: transform 0.2s ease-in-out;
}

.custom-checkbox:checked::after {
    transform: translate(-50%, -50%) scale(1);
}

@media only screen and (max-width: 768px) {

    .mainFinanceCardOpenViewShadow {
        width: 95%;
        margin-left: 2.5%;
        min-height: 22vw;
        border-radius: 3vw;
        box-shadow: 0px 0vw 1vw 0.3vw rgba(0, 0, 0, 0.25);
        transition: 0.3s ease;
    }

    .fullOpenSmartphoneCard {
        width: 100%;
        height: 100%;
        transition: 0.3s ease;

        .HeaderNoteFinancialCardView {
            font-size: 2.8vw;
            border-radius: 3vw;
            margin-top: -3%;
            margin-left: 0%;
            padding: 0.3vw 1vw 0.3vw 1vw;
            max-width: 38vw;

            p {
                padding: 0 1vw 0 1vw;
            }
        }

        header {
            height: 20%;
            margin-top: 2%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            width: 98.5%;
            margin-left: 2%;

            >div:nth-of-type(1) {
                /*categoria e recorrencia*/
                display: flex;
                align-items: center;
                flex-direction: row;
                justify-content: left;
                width: 90%;
                gap: 3%;
            }

            >div:nth-of-type(2) {
                /*credit card logo*/
                height: 100%;
                width: 10%;
                margin-top: -1%;
                display: flex;
                justify-content: right;

                img {
                    width: 5vw;
                    height: 5vw;
                }

            }
        }


        body {
            height: 67%;
            width: 96%;
            margin-left: 2%;
            font-family: Jost;

            >div:nth-of-type(1) {
                /*Nome e parcelas*/
                width: 100%;
                height: 30%;
                display: flex;
                justify-content: space-between;
                margin-top: 0.5%;
                position: relative;

                >p:nth-of-type(1) {
                    font-size: 3.5vw;
                    height: 5vw;
                    font-weight: 500;
                    max-width: 80%;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                >p:nth-of-type(2) {
                    /*parcelas*/
                    font-size: 3vw;
                    position: absolute;
                    right: 0;
                    top: 30%;
                }
            }

            >div:nth-of-type(2) {
                /*Valor*/
                font-size: 4.3vw;
                font-weight: 500;
            }

            >div:nth-of-type(3) {
                /*Data e pago*/
                font-weight: 300;
                font-size: 3vw;
                margin-top: -1%;
                display: flex;
                width: 100%;
                justify-content: space-between;
                position: relative;

                >div {
                    /*container paga recebida*/
                    position: absolute;
                    right: -1.5vw;
                    bottom: 30%;
                    display: flex;
                    width: 35%;
                    align-items: center;
                    justify-content: right;

                    >div {
                        padding: 1.5vw;
                        border-radius: 2vw;
                    }

                    p {
                        font-size: 3vw;
                        font-weight: 500;
                    }
                }
            }
        }
    }

    .custom-checkbox {
        width: 4.8vw;
        height: 4.8vw;
        border: 0.1vw solid #b6b6b6;
        box-shadow: 0px 0vw 0.5vw 0.2vw rgba(0, 0, 0, 0.25);
        border-radius: 1vw;
    }

    .custom-checkbox:checked {
        background-color: #2890ff;
        border-color: #2890ff;
    }

    .custom-checkbox::after {
        content: '';
        display: inline-block;
        width: 1.2vw;
        height: 2.9vw;
        border: solid #fafafa;
        border-width: 0 0.7vw 0.7vw 0;
        transform: rotate(45deg) !important;
        position: relative;
        left: 0.2vw;
        top: -0.5vw;
        transform: translate(-50%, -50%) scale(0);
        transition: transform 0.2s ease-in-out;
    }

    .custom-checkbox:checked::after {
        transform: translate(-50%, -50%) scale(1);
    }



    /*Open card*/
    .openFinancialCardEdit {
        min-height: 60vw;
        max-height: 60vw;
        width: 95%;
        margin-left: 2.5%;
        border-radius: 3vw;
        box-shadow: 0px 0vw 1vw 0.3vw rgba(0, 0, 0, 0.25);
        transition: 0.3s ease;

    }

    .smartphoneOpenFinancialCard {
        height: 100%;
        width: 100%;
        font-family: Jost;
        transition: 0.3s ease;

        header {
            height: 15%;
            width: 96%;
            margin-left: 2%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            >img {
                /*fechar*/
                width: 5%;
            }

            >div {
                /*tags*/
                width: 94%;
                display: flex;
                align-items: center;
                gap: 2%;
                height: 100%;
                overflow: hidden;

                /*Imagem de conta*/
                >img {
                    width: 5.5vw;
                }
            }

            .HeaderNoteFinancialCardEdit {
                font-family: Jost;
                font-size: 2.8vw;
                border-radius: 2vw;
                min-width: 10vw;
                width: fit-content;
                max-width: 45vw;
                padding: 0 1.5vw;
                margin-top: 0%;
                height: 4vw;
                display: flex;
                align-items: center;
                gap: 1vw;

                /*categorias*/
                >div {
                    min-width: 3.2vw;
                    min-height: 3.2vw;
                    max-width: 3.2vw;
                    max-height: 3.2vw;
                    padding: 0;

                    img {
                        width: 75%;
                        height: 75%;
                    }
                }


                >img {
                    min-width: 3.2vw;
                    min-height: 3.2vw;
                    margin: 0;
                    padding: 0;
                }

                >p {
                    min-width: fit-content;
                    padding: 0;
                }
            }
        }

        body {
            width: 96%;
            height: 61%;
            margin-left: 2%;

            >p:nth-of-type(1) {
                font-weight: 500;
                font-size: 4.5vw;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 100%;
                margin-top: 2%;
            }

            >div:nth-of-type(1) {
                margin-top: 1%;
                display: flex;
                justify-content: space-between;

                >p {
                    /*Parcelas*/
                    font-size: 3.2vw;
                    font-weight: 300;
                }

                >div {
                    /*data, criado e início*/

                    >p {
                        font-size: 3.7vw;
                    }

                    >p:nth-of-type(2)

                    /*criado em e final em - para parcelas*/
                    ,
                    >p:nth-of-type(3) {
                        margin-top: -4%;
                        font-weight: 300;
                        font-size: 3vw;
                    }
                }
            }

            >div:nth-of-type(2) {
                /*container observações*/

                >p:nth-of-type(1) {
                    /*titulo observacoes*/
                    font-size: 3.7vw;
                }

                >p:nth-of-type(2) {
                    /*Observações*/
                    word-wrap: break-word;
                    overflow-wrap: break-word;
                    max-height: 13vw;
                    overflow: auto;
                    font-size: 3.2vw;
                }
            }
        }

        footer {
            display: flex;
            justify-content: space-between;
            margin-top: 3%;
            width: 96%;
            margin-left: 2%;
            height: 12.5%;

            >p {
                font-size: 5vw;
                font-weight: 500;
            }

            >div {
                /*Checkbox container*/
                display: flex;
                width: 40%;
                justify-content: right;
                align-items: center;
                margin-right: -1.5vw;

                p {
                    font-size: 4vw;
                    margin-right: 3%;
                }

                >div {
                    padding: 1.5vw;
                    border-radius: 1vw;
                }
            }
        }
    }
}