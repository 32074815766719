* {
  margin: 0;
  padding: 0;
}

@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css?family=Comfortaa:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


.toast {
  border-radius: 0.5vw;
}

.Toastify__toast {
  background-color: #242424;
  color: #fafafa;
  font-family: Jost;
  font-size: 1vw;
}

@media only screen and (max-width: 768px) {
  .Toastify__toast {
    font-size: 4.2vw;
  }
}