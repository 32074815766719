.mainSectionLP {
    width: 100%;
    height: 100%;
    position: relative;
    background-image: url('/var/www/sunna_front/Sunna-Front/public/Images_LP/womanSectionOne.png');
    background-size: cover;
    background-position: 0 15%;
    z-index: 1;

    section {
        height: 80%;
        width: 80%;
        margin-top: 3vw;
        margin-left: 10vw;
        display: flex;  
        align-items: center;
        justify-content: end;

        >div:nth-of-type(1) {
            width: 31vw;
            margin-bottom: 5%;

            p {
                font-family: Jost;
                color: #fafafa;
            }

            >p:nth-of-type(1) {
                font-size: 3.4vw;
                line-height: 3.3vw;
                font-weight: 500;
                text-align: justify;

                span {
                    color: #EEC842;

                }
            }

            p:nth-of-type(2) {
                margin-top: 5%;
                font-size: 1.1vw;
                text-align: justify;
            }
        }
    }
}

.FirstSectionButtonsText {
    gap: 5%;
    margin-top: 5%;
    display: flex;
    justify-content: space-between;

    button {
        width: 15vw;
        height: 3vw;
        font-family: Jost;
        border: none;
        border-radius: 0.5vw;
        font-size: 1.5vw;
        font-weight: 600;
        cursor: pointer;
    }

    button:nth-of-type(1) {
        color: #fafafa;
        background-color: #EEC842;

        &:hover {
            background-color: #C7A630;
            transition: background-color 0.5s, color 0.5s;
        }
    }

    button:nth-of-type(2) {
        color: #EEC842;
        background-color: transparent;
        border: #EEC842 solid 0.1vw;
        transition: background-color 0.5s, color 0.5s, border 0.5s;

        &:hover {
            color: #fafafa;
            background-color: #EEC842;
            border: none;
            transition: background-color 0.5s, color 0.5s, border 0.5s;
        }
    }

}

.FadeLP {
    position: absolute;
    z-index: -1;
    top: 0;
    right: 0;
    height: 100vh;
    width: 50%;
    opacity: 50%;
    background-image: linear-gradient(to left, rgb(90, 90, 90), rgba(128, 128, 128, 0));
}


@media only screen and (max-width: 768px) {
    .mainSectionLP {
        background-position: 20% 0%;

        section {
            justify-content: center;
            margin-left: 10%;

            >div:nth-of-type(1) {
                width: 100%;
                margin-top: 50%;

                >p:nth-of-type(1) {
                    font-size: 8.5vw;
                    line-height: 9vw;
                    font-weight: 600;
                }
    
                p:nth-of-type(2) {
                    margin-top: 5%;
                    margin-top: 8%;
                    font-size: 5vw;
                    color: #ffffff;
                }
            }
        }
    }
    
    .FirstSectionButtonsText {
        button {
            width: 40vw;
            height: 10vw;
            font-size: 4.5vw;
            margin-top: 8%;
        }
    }

    .FadeLP {
        position: absolute;
        z-index: -1;
        top: 0;
        right: 0;
        height: 100vh;
        width: 100%;
        opacity: 55%;
        background-image: linear-gradient(to left, rgb(90, 90, 90), rgba(128, 128, 128, 0));
    }
}