.PorcentagemCategoriasHistoricoContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
    cursor: default;

    header {
        height: 15%;
        display: flex;
        align-items: center;
        justify-content: space-between;


        >div {
            display: flex;
            flex-direction: column;
            width: 60%;

            >p:nth-of-type(1) {
                margin-left: 5%;
                font-size: 1.9vw;
                white-space: nowrap;
                font-family: Jost;
            }

            >p:nth-of-type(2) {
                margin-left: 5%;
                margin-top: -0.8%;
                font-size: 1vw;
                opacity: 60%;
                font-family: Jost;
                display: flex;

                img {
                    width: 1vw;
                    margin-right: 0.5%;
                }
            }
        }

        >button {
            background-color: transparent;
            height: fit-content;
            width: fit-content;
            white-space: nowrap;
            border: none;
            cursor: pointer;
            margin-right: 3%;
            display: flex;
            align-items: center;

            img {
                width: 1.6vw;
            }
        }
    }

    >div:nth-of-type(1) {
        display: block !important;
        height: 90%;
        margin-left: 2.5%;
        width: 95%;
    }

    >div:nth-of-type(2) {
        display: none !important;
    }
}

@media only screen and (max-width: 768px) {
    .PorcentagemCategoriasHistoricoContainer {
        header {
            >div {
                display: flex;
                flex-direction: column;
                width: 60%;

                >p {
                    white-space: nowrap;
                }

                >p:nth-of-type(1) {
                    margin-left: 5%;
                    font-size: 5vw;
                    font-family: Jost;
                }

                >p:nth-of-type(2) {
                    margin-left: 5%;
                    margin-top: -0.8%;
                    font-size: 3.2vw;
                    opacity: 60%;
                    font-family: Jost;
                    display: flex;

                    img {
                        min-width: 3.5vw;
                        margin-right: 0.5%;
                    }
                }
            }

            >button {
                background-color: transparent;
                height: fit-content;
                width: fit-content;
                white-space: nowrap;
                border: none;
                cursor: pointer;
                margin-right: 3%;
                display: flex;
                align-items: center;

                img {
                    width: 6vw;
                }
            }
        }

        >div:nth-of-type(1) {
            height: 90%;
            margin-left: 2.5%;
            width: 95%;
        }

    }

}