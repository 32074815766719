.smartPhoneSwitch{
  display: none;
}

#toggle[type='checkbox'] {
  display: none;
}


@media (min-width: 1025px) and (max-width: 1366px) {}

@media only screen and (max-width: 1000px) {}

@media only screen and (max-width: 768px) {

}