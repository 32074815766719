@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.DropboxContainer {
    width: 100%;
    border-radius: 0.3vw;
    background-color: rgba(255, 255, 255, 1);
    transition: background-color 0.3s ease;
    position: relative;
    user-select: none;
    border: solid 0.05vw;

    /*Título da dropbox*/
    >div:nth-of-type(1) {
        width: 100%;
        height: 1.9vw;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;

        &:hover {
            background-color: rgb(36, 36, 36, 0.07);
            transition: background-color 0.3s ease;
        }

        /*arrow*/
        >img {
            width: 0.8vw;
            margin-right: 2.5%;
        }

        >div {
            font-family: Jost;
            margin-left: 1%;
            width: 92.5%;
            display: flex;
            align-items: center;

            >img {
                margin-right: 2.5%;
                height: fit-content;
                width: 1.4vw;
                max-height: 1.4vw;
            }

            /*Depois de ser selecionado*/
            >p {
                width: 100%;
                font-size: 0.85vw;

                >div {
                    display: flex;
                    align-items: center;
                    min-height: 2vw;
                    width: 100%;

                    >p {
                        font-size: 0.85vw;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;

                    }

                    /*Imagem da categoria*/
                    >div {
                        margin-left: 1%;
                        min-width: 1.5vw;
                        min-height: 1.5vw;
                        max-width: 1.5vw;
                        max-height: 1.5vw;
                        border-radius: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-right: 2.5%;

                        >img {
                            width: 60%;
                            height: 60%;
                        }
                    }

                    /*logo banco*/
                    >img {
                        border-radius: 50%;
                        width: 1.4vw;
                        height: 1.4vw;
                        margin-right: 2.5%;
                    }

                }
            }

            >div {
                /*Com ícone*/
                display: flex;
                align-items: center;
                border: solid 1vw;
                p {
                    margin-left: 2%;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;

                }

                >img {
                    /*Default Image*/
                    width: 1.3vw;

                }

                >div {
                    /*categoria*/
                    min-width: 1.5vw;
                    min-height: 1.5vw;
                    max-width: 1.5vw;
                    max-height: 1.5vw;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50%;

                    >img {
                        max-width: 70%;
                        max-height: 70%;
                    }
                }

            }
        }

    }

    /*Dropbox*/
    >div:nth-of-type(2) {
        position: absolute;
        overflow-y: auto;
        top: 0;
        margin-top: 2.05vw;
        width: 99.5%;
        max-height: 16vw;
        background-color: rgba(255, 255, 255, 1);
        z-index: 2;
        border-radius: 0.3vw;
        border: #242424 solid 0.05vw;
        animation: fadeIn 0.4s forwards;
        font-family: Jost;

        >div {
            white-space: nowrap;
            overflow: hidden;
            display: flex;

            >div {
                max-height: 1.3vw;
                width: 100%;
                display: flex;
                align-items: center;
                padding: 0.5vw 0.2vw 0.5vw 0.2vw;
                border-bottom: solid 0.1vw #d6d6d6;

                >img {
                    /*logo todas as categorias*/
                    width: 1.3vw;
                }

                >div {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50%;
                    height: 1.3vw;
                    width: 1.3vw;

                    img {
                        max-height: 60%;
                        max-width: 60%;
                    }
                }

                >p {
                    margin-left: 0.4vw;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    width: 90%;
                }
            }

            &:hover {
                background-color: rgb(36, 36, 36, 0.07);
                transition: background-color 0.3s ease;
            }
        }
    }

}

@media only screen and (max-width: 768px) {
    .DropboxContainer {
        width: 99%;
        border-radius: 1vw 1vw 0 0;
        box-shadow: 0px 0vw 0.8vw 0.3vw rgba(0, 0, 0, 0.25);
        position: relative;

        /*Título da dropbox*/
        >div:nth-of-type(1) {
            display: flex;
            justify-content: center;
            height: 100%;
            max-width: 100%;

            >img {
                width: 3.5vw;
                padding-right: 1vw;
                padding-left: 1vw;
            }



            >div {
                max-width: 100%;
                overflow: hidden;

                /*Imagem default*/
                >img {
                    min-width: 5vw;
                    min-height: 5vw;
                }

                /*Título*/
                >p {
                    font-weight: 500;
                    font-size: 3.5vw;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                /*pós seleção*/
                >p {

                    /*container do item selecionado*/
                    >div {

                        >p{
                            font-weight: 500;
                            font-size: 3.5vw;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }

                        >img{
                            min-width: 4.5vw;
                            min-height: 4.5vw;
                        }
                        /*Bolinha categoria*/
                        >div {
                            min-width: 4.5vw;
                            min-height: 4.5vw;
                        }
                    }
                }
            }
        }

        /*Dropbox*/
        >div:nth-of-type(2) {
            max-height: 50vw;
            border-radius: 0 0 1vw 1vw;
            width: 99%;
            top: 6vw;
            position: absolute;

            /*Div pai de cada item da bandeja. Mas ainda é do indexDropBox*/
            >div {
                height: 8vw;
                display: flex;
                align-items: center;

                /*Englobador já passado via parâmetro*/
                >div {
                    min-height: 100%;
                    width: 98%;
                    margin-left: 1%;
                    border-bottom: solid 1vw #d6d6d6;

                    /*Imagem da bandeja - exceto para categorias que não seja o "todas as categorias". O todas as categorias está aqui, pois é uma img direto*/
                    >img {
                        width: 4.5vw;
                    }

                    /*texto do item da bandeja*/
                    >p {
                        margin-left: 2%;
                        font-size: 3.4vw;
                    }

                    /*Bolinha categoria*/
                    >div {
                        width: 4.5vw;
                        height: 4.5vw;
                        /*Imagem categoria*/
                        >img {
                            width: 70%;
                        }
                    }
                }
            }
        }
    }
}