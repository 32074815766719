.MoreOptionsSelectionContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 27.5%;
    height: 40vw;
    background-color: #fafafa;
    border-radius: 1vw;
    padding: 3vw;
    box-shadow: 0px 0vw 2vw 0.1vw rgba(0, 0, 0, 0.25);
    position: absolute;
    bottom: 10vh;

    >div {
        border-bottom: 1px solid #e0e0e0;
        font-family: Jost;
        width: 100%;
        display: flex;
        align-items: center;

        >p{
            width: 100%;
            text-align: left;
            font-size: 4vw;
        }

        >img{
            width: 3.3vw;
        }

    }
}