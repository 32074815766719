.IncomeSpendSummaryContainer {
    font-family: Jost;

    >header {
        width: 96%;
        margin-left: 2%;
        justify-content: space-between;
        height: 37%;

        >p:nth-of-type(1) {
            font-size: 1.2vw;
        }

        >p:nth-of-type(2) {
            margin-top: 1%;
            font-size: 1.5vw;

            >b {
                font-weight: 500;
            }
        }

    }

    >body {
        width: 96%;
        margin-left: 2%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        height: 63%;
        font-size: 0.85vw;

        >div {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 80%;

            img {
                width: 1vw;
            }

            >p {
                margin-top: 4.5%;
            }

            >p:nth-of-type(1) {
                font-family: Comfortaa;
            }

            >p:nth-of-type(2) {}
        }
    }
}

#toPaiReceiveGrey {
    color: #6C6C6C;
}

@media (max-width: 768px) {

    .IncomeSpendSummaryContainerSmartphone {
        display: flex;
        justify-content: space-between;
        width: 55%;
        margin-left: 2.5%;

        >div:nth-of-type(1) {
            width: 65%;
            display: flex;
            align-items: center;

            /*logo*/
            >div {
                width: 4.9vw;
                height: 4.9vw;
                box-shadow: 0 0 1vw 0.4vw rgba(0, 0, 0, 0.25);
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;

                img {
                    width: 45%;
                }
            }

            p {
                font-family: Jost;
                font-size: 4vw;
                margin-left: 5%;


            }
        }

        >p{
            width: 35%;
            font-family: Jost;
            white-space: nowrap;
            font-size: 3.8vw;

            span{
                font-weight: 500;
            }
        }
    }

}