.containerInvestiments {
    height: calc(var(--vh, 1vh) * 100);
    padding-top: env(safe-area-inset-top);
    padding-bottom: env(safe-area-inset-bottom);
    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);
    display: flex;

    >aside {
        position: fixed;
        width: 13%;
        transition: width 0.5s;

    }

    >div {
        box-shadow: 0px 0.2vw 0.2vw #363636;
        margin-left: 13%;
        width: 87%;
        background-color: #F5F5F5;
        overflow: hidden;

        >header {
            margin-left: 3%;
            position: fixed;
            z-index: 1;
            width: 87%;
            height: 8vw;
        }

        >section {
            margin-left: 3%;
            width: 100%;
            margin-top: 8vw;
            height: 80%;
        }
    }
}
