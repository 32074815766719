.MainContainerHeader{
    width: 90%;
    margin-left: 5%;
    height: 10%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    img{
        width: 10vw;
        cursor: pointer;
    }

    button{
        background-color: #EEC842;
        border: none;
        font-family: Jost;
        font-weight: 500;
        font-size: 1.5vw;
        color: #ffffff;
        border-radius: 0.3vw;
        width: fit-content;
        padding: 0 3vw;
        cursor: pointer;
        box-shadow: 0.1vw 0.5vw 1vw 0 rgba(0, 0, 0, 0.25);
        transition: background-color 0.3s;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover{
            background-color: #C7A630;
            transition: background-color 0.5s;
        }
    }
}

/* Mobile settings */
@media only screen and (max-width: 768px) {
    .MainContainerHeader{
        width: 90%;
        margin-left: 5%;
        height: 10%;
    
        img{
            width: 30vw;
        }
    
        button{
            width: fit-content;
            height: fit-content;
            padding: 0vw 6vw;
            font-weight: 400;
            font-size: 5vw;
            border-radius: 1.5vw;
        }
    }
}