@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.edit-window-overlay {
    position: fixed;
    height: 100%;
    width: 100%;
    background-color: rgb(0, 0, 0, 0.3);
    z-index: 5;
    top: 0;
    left: 0;
    overflow: hidden;
    animation: fadeIn 0.5s forwards;
}

.edit-window-main-container {
    position: fixed;
    height: 100%;
    width: 40%;
    background-color: #FAFAFA;
    right: 0;
    top: 0;
    z-index: 5;
    animation: fadeIn 0.5s forwards;

    >header {
        height: 10%;
        width: 95%;
        margin-left: 2.5%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 0.1vw solid #D1D1D1;

        p {
            font-family: Jost;
            font-size: 2vw;
            font-weight: 500;
        }
    }

    >body {
        height: 90%;
    }
}


@media (max-width: 768px) {
    .edit-window-overlay {
        min-height: 100vh;
        background-color: rgb(0, 0, 0, 0.3);
        width: 100%;
        z-index: 5;
    }

    .edit-window-main-container {
        width: 100%;

        >header {
            height: 10%;
            width: 95%;
            margin-left: 2.5%;
            border-bottom: 0.1vw solid #D1D1D1;
            display: flex;
            justify-content: left;

            p {
                margin-left: 3%;
                font-family: Comfortaa;
                font-size: 5.5vw;
                font-weight: 500;
                white-space: nowrap;
            }

            img{
                width: 6vw;
            }
        }

        >body {
            height: 90%;
        }
    }
}