.HistoricoReceitasGastosContainer{
    cursor: default;

    header{
        height: 15%;
        width: 95%;
        margin-left: 2.5%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-family: Jost;
        border-bottom: 0.1vw solid #D1D1D1;

        p{
            font-size: 1.8vw;
            white-space: nowrap;
        }
    }

    .chart-container-desktop {
        position: relative;
        margin-left: 2.5%;
        height: 85%;
        width: 95%; 
    }
    .chart-container-smartphone{
        display: none;
    }
    
}


@media only screen and (max-width: 768px) {
    .HistoricoReceitasGastosContainer{
    
        header{
            p{
                font-size: 4.5vw;
                text-align: center;
                width: 100%;
            }
        }
    
        .chart-container-desktop {
            display: none;
            position: relative;
            margin-left: 0%;
            height: 85%;
            width: 100%; 
        }

        .chart-container-smartphone{
            display: block;
            position: relative;
            margin-left: 0%;
            height: 85%;
            width: 100%; 
        }
        
    }
}