.bank-selector-container {
    height: 100%;
    width: 98%;
    padding: 0 1%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(17%, 1fr));
    grid-auto-rows: min-content;
    overflow-y: auto;
    gap: 3%;
}


.bank-selector-button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 0.2vw 0.05vw rgba(0, 0, 0, 0.25);
    border-radius: 1vw;
    padding: 3%;
    margin: 5% 0;
    height: 5.5vw;
    cursor: pointer;
    margin-top: 5%;
    transition: background-color 0.3s ease;

    img {
        width: 3vw;
        border-radius: 30%;
    }

    p {
        font-family: Jost;
        font-size: 0.95vw;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 6vw;
        user-select: none;
    }
}

@media (max-width: 768px) {
    .bank-selector-container {
        grid-template-columns: repeat(auto-fill, minmax(25%, 1fr));
        gap: 3%;
    }

    .bank-selector-button {
        height: 23vw;
        box-shadow: 0 0 0.8vw 0.3vw rgba(0, 0, 0, 0.25);
        border-radius: 2vw;

        img {
            width: 10vw;
            border-radius: 30%;
        }

        p {
            font-family: Jost;
            font-size: 4vw;
            text-align: center;
            max-width: 25vw;
            user-select: none;
        }
    }
}