.LoginPageSunna {
    width: 100%;
    height: calc(var(--vh, 1vh) * 100);
    padding-top: env(safe-area-inset-top);
    padding-bottom: env(safe-area-inset-bottom);
    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);

    >section {
        display: flex;
        justify-content: center;
        gap: 5%;
        margin-top: 3%;

        >img {
            width: 45vw;
        }

        >div:nth-of-type(2) {
            width: 30vw;
        }
    }
}


@media only screen and (max-width: 1000px) {
    .LoginPageSunna {

        >section {
            margin-top: 15%;
            height: 80%;


            img {
                display: none;
            }

            >div:nth-of-type(2) {
                width: 85vw;
            }
        }
    }
}