.headerContainerOpenFinanceDesktop {
    height: 12%;
    position: relative;

    >p {
        position: absolute;
        font-family: Jost;
        width: fit-content;
        text-align: end;
        right: 2%;
        bottom: 5%;
        font-size: 0.9vw;
        color: #CC2E43;

        &:hover {
            text-decoration: underline;
            cursor: pointer;
        }
    }

    >div {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: end;
        height: 100%;
        width: 97.5%;
        margin-left: 1%;

        >div:nth-of-type(1) {
            width: 100%;
            height: 2vw;
            display: flex;
            align-items: center;
            justify-content: end;
            gap: 1%;

            >div {
                min-width: 19%;
                max-width: 19%;
            }
        }

        >div:nth-of-type(2) {
            width: 24%;
        }
    }
}

@media only screen and (max-width: 768px) {
    .headerSmartphoneAllFinancesView {
        height: auto;
        position: relative;

        >div:nth-of-type(1) {
            /*Meus gastos de x*/
            width: 90%;
            margin-left: 5%;
            height: fit-content;
            margin-top: 5%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            >p {
                font-family: Comfortaa;
                font-size: 5vw;
            }

            >img {
                width: 7%;
            }
        }

        >div:nth-of-type(2) {
            /*Total no mes*/
            display: flex;
            flex-direction: column;
            width: 90%;
            margin-left: 5%;
            margin-top: 5%;
            font-family: Jost;
            border-bottom: 0.1vw solid #D9D9D9;

            >p:nth-of-type(1) {
                font-size: 5vw;
            }

            >p:nth-of-type(2) {
                font-size: 7vw;
                font-weight: 500;
            }
        }

        >div:nth-of-type(3)::-webkit-scrollbar {
            display: none;
        }

        >div:nth-of-type(3) {
            /*Filtros*/
            background-color: transparent;
            width: 100%;
            margin-top: 3%;
            display: flex;
            justify-content: space-between;
            gap: 3%;
            scrollbar-width: none;
            -ms-overflow-style: none;
            transition: margin-left 0.3s ease;
            position: relative;

            >button {
                position: fixed;
                z-index: 8;
                border: none;
                background-color: transparent;
                height: 7.3vw;
            }

            #leftButtonScroll {
                left: 0;
                width: 12vw;
            }

            #rightButtonScroll {
                right: 0;
                width: 17vw;
            }

            >div {
                height: 7vw;
                border-radius: 1vw;
            }

            /*contas*/
            >div:nth-of-type(1) {
                min-width: 32vw;
            }

            /*origem*/
            >div:nth-of-type(2) {
                min-width: 35vw;
            }

            /*categorias*/
            >div:nth-of-type(3) {
                min-width: 43vw;
            }

            /*recebidas*/
            >div:nth-of-type(4) {
                min-width: 35vw;
            }

            /*ordenar*/
            >div:nth-of-type(5) {
                min-width: 35vw;
            }
        }

        .ClearFilterSmarthphone {
            font-family: Jost;
            width: 92%;
            margin-left: 4%;
            display: flex;
            justify-content: end;
            margin-top: 2%;
            color: #CC2E43;
            text-decoration: underline;
            font-size: 3.8vw;
            height: 4vw;
        }
    }
}