.sizeShortFinancialCard {
    height: 100%;
    
    >div{
        cursor: pointer;
    }
}

.IncomeReducedCardHeader {
    height: 3vw;
    width: 90%;
    margin-left: 5%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    >p:nth-of-type(1) {
        color: #242424;
        font-family: Jost;
        font-size: 1.6vw;
        font-weight: 400;
    }

    >p:nth-of-type(2) {
        display: none;
    }

    /*New Finance button*/
    >div {
        display: flex;
        position: relative;
        width: 6.2vw;
        align-items: center;
        justify-content: space-between;

        >p{
            font-family: Comfortaa;
            font-size: 0.8vw;
        }

        >div:nth-of-type(1) {
            position: absolute;
            right: 0;
            height: 0.9vw;
            width: 0.15vw;
            border-radius: 0.2vw;
            transform: rotate(90deg);
        }

        >div:nth-of-type(2) {
            height: 0.9vw;
            width: 0.15vw;
            border-radius: 0.2vw;
        }
    }
}

.incomeButtonColor {

    >div {
        background-color: #489946;

        &:hover {
            background-color: #428941;
        }
    }
}

.spendButtonColor {
    >div {
        background-color: #ED4C5C;

        &:hover {
            background-color: #B53541;
        }
    }
}

.IncomeReducedCardTableData {
    margin-top: 1%;
    max-height: 75%;
    overflow-y: auto;

    >p{
        font-family: Jost;
        font-size: 1vw;
        margin-left: 5%;
    }
}

@media only screen and (max-width: 768px) {
    .IncomeReducedCardHeader {
        height: 20%;
        border-bottom: 0.3vw solid #bcbcbc;
        font-family: Jost;

        >p:nth-of-type(1) {
            font-size: 5.7vw;
        }

        >button {
            display: none;
        }

        >p:nth-of-type(2) {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 33%;
            font-size: 3.5vw;
            font-family: Comfortaa;

            img {
                width: 3vw;
                transform: rotate(270deg);
            }
        }
    }

    .IncomeReducedCardTableData {
        margin-top: 1%;
        height: 70%;
        max-height: 70%;
        overflow-y: auto;

        >p{
            font-family: Jost;
            font-size: 4vw;
            margin-left: 5%;
        }
    }
}