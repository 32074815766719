.creditCardRenderedContainer {
    width: 95%;
    margin-left: 2.5%;
    height: 3.5vw;
    margin-top: 1.5%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 1.5%;
    border-bottom: #D1D1D1 solid 1px;

    /*img, nome, vencimento, acessar fatura*/
    >div:nth-of-type(1) {
        display: flex;
        justify-content: space-between;
        align-items: center;

        /*img, nome e vencimento/fechamento*/
        >div:nth-of-type(1) {
            display: flex;
            width: 70%;

            >img {
                border-radius: 0.3vw;
                width: 2vw;
            }

            >div {
                margin-left: 2.5%;
                font-family: Jost;
                display: flex;
                flex-direction: column;
                justify-content: center;

                >p:nth-of-type(1) {
                    font-size: 0.95vw;
                }

                >p:nth-of-type(2) {
                    font-size: 0.8vw;
                    color: #636363;
                }
            }
        }

        >div:nth-of-type(2) {
            display: flex;
            align-items: center;
            gap: 3%;
            width: 25%;
            justify-content: end;
            height: fit-content;

            p {
                font-family: Comfortaa;
                color: #595959;
                font-size: 0.8vw;
                cursor: pointer;
            }

            img {
                width: 0.35vw;
                cursor: pointer;
            }
        }
    }

    /*Limite e fatura atual*/
    >div:nth-of-type(2) {
        display: flex;
        justify-content: end;
        font-family: Jost;
        font-size: 0.8vw;

        /*Limite/Fatura atual*/
        p {
            color: #636363;
        }

        /*Valor Limite*/
        b {
            color: #C22929;
            font-weight: 400;
        }
    }
}

@media (max-width: 768px) {
    .creditCardRenderedContainer {
        height: 12vw;
        margin-top: 1.5%;
        padding-bottom: 3.5%;


        /*img, nome, vencimento, acessar fatura*/
        >div:nth-of-type(1) {

            /*img, nome e vencimento/fechamento*/
            >div:nth-of-type(1) {
                display: flex;
                width: 65%;

                >img {

                    width: 8vw;
                    border-radius: 1vw;
                }

                >div {
                    margin-left: 2.5%;
                    font-family: Jost;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;

                    >p:nth-of-type(1) {
                        font-size: 3.9vw;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        max-width: 50vw;
                    }

                    >div:nth-of-type(1) {
                        display: flex;
                        align-items: center;
                        gap: 1vw;

                        p {
                            font-size: 3.6vw;
                            color: #636363;

                        }

                        img {
                            width: 1.2vw;
                            margin-top: 0.8vw;
                        }
                    }
                }
            }

            >div:nth-of-type(2) {
                justify-content: end;
                margin-bottom: 4%;
                width: 35%;

                p {
                    font-size: 4vw;
                    color: #C22929;
                    font-family: Jost;

                    >b{
                        font-weight: 400;
                    }
                }
            }
        }
    }
}