.chartCategoriesContainer {
    cursor: default;
    position: relative;
}

.sizeCategoriesGraph {
    height: 100%;
    max-width: 100%;
}

.desktopTabletCategoriesContainer {
    height: 73%;
    width: 100%;
    position: relative;
}

.chartCategoriesHeader {
    height: 20%;
    width: 90%;
    margin-left: 5%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: solid 0.1vw #D1D1D1;

    > p {
        color: #242424;
        font-family: Jost;
        font-size: 1.7vw;
        font-weight: 400;
    }
}

.bodyCategoriesGraph {
    height: 100%;
    width: 90%;
    margin-left: 2.5%;
    display: flex;
    align-items: start;
    justify-content: space-around;
}

.GraphCategories {
    height: 90%;
    margin-top: 2.5%;
    width: 30%;
}

.tableCategoriesContainer {
    width: 60%;
    height: 100%;
    overflow-y: auto;
}

.bodyCategoriesGraph table {
    font-family: Jost;
    width: 95%;
    margin-top: 2%;
    margin-left: 5%;
}

.borderBottomTitleTableCategories {
    border-bottom: #D1D1D1 solid 0.1vw;
}

.CenterTextTableCategories {
    text-align: center;
}

.LeftTextTableCategories {
    text-align: left;
}

.bodyCategoriesGraph th {
    font-weight: 400;
    font-size: 1vw;
}

.bodyCategoriesGraph td {
    font-weight: 400;
    font-size: 0.8vw;
    max-width: 6vw;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.colorgroup {
    width: 0.7vw;
    height: 0.7vw;
    border-radius: 100%;
    display: flex;
    align-self: center;
    justify-content: center;
}

.NoGroupInData {
    text-align: center;
    font-family: Jost;
    font-size: 1.4vw;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}


@media only screen and (max-width: 768px) {

    .chartCategoriesHeader {
        height: 15%;
        border-bottom: #D1D1D1 solid 0.1vw;
        display: flex;
        align-items: center;

        >p:nth-of-type(1) {
            font-size: 5.5vw;
        }

        >div{
            height: fit-content;
        }

    }

    .categoriesSmartPhoneContainer {
        display: block;
        height: 80%;
        width: 100%;
        overflow: auto;
    }

    .mobileTableCategoriesValues {
        width: 90%;
        margin-left: 5%;
        font-family: Jost;

        th {
            text-align: center;
            font-weight: 400;
            font-size: 4.2vw;
            white-space: nowrap;
        }

        td {
            text-align: center;
            font-size: 3.6vw;
            color: #383227;
        }

        .textAlignLeft {
            text-align: left;
        }
    }

    #categorieNameTable {
        max-width: 35vw;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .colorgroup {
        width: 3vw;
        height: 3vw;
    }

    .NoGroupInData {
        font-size: 4vw;
    }
    
}