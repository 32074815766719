.mainContainerErrorPage {
    height: calc(var(--vh, 1vh) * 100);
    padding-top: env(safe-area-inset-top);
    padding-bottom: env(safe-area-inset-bottom);
    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);
    position: relative;
}

.headerErrorPage {
    height: 20%;
    width: 100%;
    display: flex;
    align-items: end;
}

.headerErrorPage img {
    width: 20vw;
    margin-left: 5vw;
    user-select: none;
}

.mainContainerBodyErrorPage {
    height: 80%;
    width: 100%;
    display: flex;
}

.mainContainerText {
    width: fit-content;
    width: 50%;
    height: 80%;
    font-family: Jost;
    margin-top: 7vw;
    display: flex;
    justify-content: center;
}

.textErrorPageError div {
    margin-top: 1%;
}

.textErrorPageError {
    width: 80%;
}

.titlePageError p {
    font-size: 2.85vw;
    text-align: center;
}

.titlePageError span {
    color: #EEC842;
    font-weight: 500;
}

.textPageError {
    color: #575757;
    display: flex;
    justify-content: center;
}

.textPageError p {
    font-size: 1.7vw;
    text-align: center;

}

.linkReturnErrorPage {
    display: flex;
    justify-content: right;
}

.linkReturnErrorPage a {
    color: #EEC842;
    font-size: 1.2vw;
}

.imageErrorPageError {
    width: 50%;
    height: 80%;
    display: flex;
    justify-content: center;
    user-select: none;
}

#retangleBottom {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 35%;
    z-index: -1;
    user-select: none;
}

@media only screen and (max-width: 768px) {
    .headerErrorPage {
        height: 10%;
    }

    .headerErrorPage img {
        width: 40vw;
    }

    .mainContainerBodyErrorPage {
        height: 80%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .mainContainerText {
        width: fit-content;
        min-width: 100%;
        height: 20%;
        font-family: Jost;
        margin-top: 10vw;
    }

    .textErrorPageError div {
        margin-top: 1%;
    }

    .textErrorPageError {
        width: 90%;
    }

    .titlePageError p {
        font-size: 8vw;
        text-align: center;
    }

    .textPageError p {
        font-size: 5vw;
        text-align: center;

    }

    .linkReturnErrorPage {
        display: flex;
        justify-content: right;
    }

    .linkReturnErrorPage a {
        color: #EEC842;
        font-size: 4vw;
    }

    .imageErrorPageError {
        width: 50%;
        height: 50%;
        display: flex;
        justify-content: center;
        user-select: none;
        margin-top: 30vw;
        img{
            width: 90vw;
            height: 75vw;
        }
    }

    #retangleBottom {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 35%;
        z-index: -1;
        user-select: none;
    }

}