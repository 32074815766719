.mainContainerSectionTwo {
    width: 100%;
    height: 80%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;

    >div:first-child {
        position: absolute;
        margin-top: -10%;
        background-color: #EEC842;
        height: 90%;
        width: 110%;
        transform: rotate(-2deg);

        >section {
            height: 100%;
            margin-left: 5%;
            width: 90%;
            display: flex;
            position: relative;
            transform: rotate(+2deg);
        }
    }
}

.textAndCardsSectionTwo {
    font-family: Jost;
    color: white;
    width: 50%;
    height: 100%;
    margin-top: 5vw;
    margin-left: 5vw;
    display: flex;
    flex-direction: column;
    align-items: center;

    >p:nth-of-type(1) {
        text-align: left;
        font-size: 4vw;
        width: 100%;
        font-weight: 600;
    }

    >p:nth-of-type(2) {
        font-size: 1.5vw;
        font-weight: 300;

        span {
            font-weight: 500;

        }
    }

    >section {
        width: 100%;
        height: 50%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

}

.SmartPhoneSectionTwo {
    width: 50vw;
    position: absolute;
    right: 0vw;
    top: 6vw;
    user-select: none;
}

.mainContainerCardSectionTwo {
    height: 50%;
    width: 30%;
    background-color: #FFFFFF;
    border-radius: 0.5vw;

    >div:nth-of-type(1) {
        height: auto;
        display: flex;
        align-items: center;

        img {
            height: 1.7vw;
            margin-top: 15%;
            margin-left: 10%;
            user-select: none;
        }
    }

    >div:nth-of-type(2) {
        height: max-content;
        display: flex;
        align-items: center;
        width: 80%;
        margin-left: 10%;

        p {
            margin-top: 5%;
            color: #242424;
            font-family: Jost;
            font-size: 0.9vw;
        }
    }
}


@media only screen and (max-width: 768px) {
    .mainContainerSectionTwo {
        height: 95vw;

        >div:first-child {
            margin-top: -15%;

            >section {
                margin-left: 2.5%;
                width: 95%;
            }
        }
    }

    .textAndCardsSectionTwo {
        width: 90%;
        margin-left: 5%;

        >p:nth-of-type(1) {
            font-size: 8vw;
        }

        >p:nth-of-type(2) {
            font-size: 4vw;
            font-weight: 300;
            text-align: justify;
        }

        >section {
            margin-top: 2.5%;
            width: 100%;
            height: 25%;
        }

    }

    .SmartPhoneSectionTwo {
        width: 90vw;
        right: 10vw;
        top: 60vw;
    }

    .mainContainerCardSectionTwo {
        height: 90%;
        width: 32%;
        padding-bottom: 3%;

        >div:nth-of-type(1) {
            img {
                height: 5vw;
            }
        }

        >div:nth-of-type(2) {
            p {
                font-size: 3vw;
            }
        }
    }
}