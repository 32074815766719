.Section5 {
    position: relative;
    display: flex;
    background-color: #242424;
    border-bottom: 1vw solid #EEC842;
    padding: 5% 0px;

    >img:nth-of-type(1) {
        position: absolute;
        height: 45vw;
        bottom: 0;
    }

    >div:nth-of-type(1) {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-left: 50%;

        h1 {
            font-family: Jost;
            font-size: 2.8vw;
            color: #EEC842;
        }

        h2 {
            text-align: center;
            font-family: Jost;
            font-size: 1.1vw;
            color: white;
            font-weight: 300;
        }

        >div:nth-of-type(1) {
            display: flex;
            justify-content: center;

            img {
                height: 1.2vw;
            }

            p {
                font-size: 0.8vw;
                color: white;
                font-family: Jost;
            }

            div {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 10vw;
                padding: 2.5vw 2.5vw;
                border-radius: 3%;
                margin-top: 10%;
            }

        }
    }
}


/* Tablet or small screens settings */
@media only screen and (max-width: 1000px) {
    .Section5 {

        >img:nth-of-type(1) {
            height: 45vw;
        }

        >div:nth-of-type(1) {
            h1 {
                font-size: 3.5vw;
            }

            h2 {
                font-size: 1.4vw;
            }

            >div:nth-of-type(1) {

                img {
                    height: 2.4vw;
                }

                p {
                    width: 10vw;
                    font-size: 1.1vw;
                }


            }
        }
    }

}

@media only screen and (max-width: 768px) {
    .Section5 {
        justify-content: center;

        >img:nth-of-type(1) {
            display: none;
        }

        >div:nth-of-type(1) {
            margin-left: 0%;
            width: 100%;

            h1 {
                font-size: 6vw;
            }

            h2 {
                font-size: 4vw;
                width: 95%;
                margin-left: 2.5%;
                text-align: center;
            }

            >div:nth-of-type(1) {
                width: 90%;

                div {
                    width: 25vw;
                }


                img {
                    height: 6vw;
                }

                p {
                    white-space: nowrap;
                    text-align: center;
                    font-size: 2.5vw;
                    width: fit-content;
                }


            }
        }
    }
}