@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.overlay-generic-window {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    animation: fadeIn 0.5s forwards;

}

.content-generic-window {
    background-color: #fafafa;
    border-radius: 0.3vw;
    box-shadow: 0.1vw 0.5vw 1vw 0 rgba(0, 0, 0, 0.25);
    animation: fadeIn 0.5s forwards;

    >header {
        height: 15%;
        display: flex;
        align-items: center;
        border-bottom: solid 1px #D1D1D1;

        h1 {
            font-family: Comfortaa;
            font-size: 1.7vw;
            margin-left: 2.5%;
        }
    }

    >body {
        height: 82%;
        overflow-y: auto;
        width: 100%;
    }
}


@media only screen and (max-width: 768px) {
    .content-generic-window {
        background-color: #fafafa;
        border-radius: 1.5vw;
        box-shadow: 0.1vw 0.5vw 1vw 0 rgba(0, 0, 0, 0.25);
        animation: fadeIn 0.5s forwards;
    
        >header {
            height: 10%;
            display: flex;
            align-items: center;
            border-bottom: solid 1px #D1D1D1;
    
            h1 {
                font-family: Comfortaa;
                font-size: 5.5vw;
                margin-left: 2.5%;
            }
        }
    
        >body {
            height: 82%;
            overflow-y: auto;
            width: 100%;
        }
    }
}