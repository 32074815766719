@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.account-edit-container {
    width: 100%;
    height: 100%;
    position: relative;

    >body {
        height: 84%;
        display: flex;
        flex-direction: column;
        gap: 2%;
        overflow: auto;
        padding: 2% 0;
    }

    >footer {
        height: 10%;
        display: flex;
        align-items: center;
        justify-content: center;

        button {
            font-family: Jost;
            border: none;
            background-color: #65C37A;
            font-size: 1.6vw;
            font-weight: 600;
            width: 90%;
            height: 2.5vw;
            color: #fafafa;
            border-radius: 0.8vw;
            cursor: pointer;

            &:hover {
                background-color: #4BB763;
                transition: background-color 0.3s ease;
            }
        }
    }
}

.account-card-rendered-view {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin-left: 2.5%;
    font-family: Jost;
    border-radius: 1vw;
    box-shadow: 0 0 0.2vw 0.05vw rgba(0, 0, 0, 0.25);
    padding: 0 2.5%;
    transition: min-height 0.2s ease, background-color 0.3s ease;

    >div:nth-of-type(1) {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 2.75%;

        /*Imagem e nome*/
        >div {
            display: flex;
            align-items: center;
            width: 50%;
            max-width: 50%;

            img {
                width: 2.5vw;
                border-radius: 50%;
            }

            p {
                font-size: 1.1vw;
                margin-left: 3%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        >p {
            font-size: 1.2vw;
            font-weight: 500;
            width: 50%;
            text-align: right;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }


    }

    &:hover {
        background-color: rgb(0, 0, 0, 0.05);
    }
}

.account-card-in-edit-view {
    height: 7vw;
    padding-top: 1%;
    margin-top: 2.5%;
    border-top: solid 0.1vw #D1D1D1;
    display: flex;
    flex-direction: column;
    animation: fadeIn 0.3s forwards;
    font-family: Jost;

    /*inputs*/
    >div:nth-of-type(1) {
        display: flex;
        justify-content: space-between;
        height: 70%;

        >div {
            display: flex;
            flex-direction: column;
            width: 45%;
            display: flex;
            flex-direction: column;
            align-items: end;

            label {
                width: 100%;
            }

            input {
                width: 100%;
                background-color: #fff;
                border-radius: 0.2vw;
                height: 1.7vw;
                border: solid 0.1vw #5A5A5A;
                font-family: Jost;
                font-size: 1vw;
                color: #575757;
                text-indent: 2%;

                &:focus {
                    outline: none;
                }
            }
        }
    }

    /*botões*/
    >div:nth-of-type(2) {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;

        >p:nth-of-type(1) {
            font-size: 0.9vw;
            color: #65C37A;
            cursor: pointer;

            &:hover {
                text-decoration: underline;
            }
        }

        >p:nth-of-type(2) {
            font-size: 0.8vw;
            color: #242424;
            cursor: pointer;

            &:hover {
                color: #ED4C5C;
                text-decoration: underline;
            }
        }
    }
}


.create-new-account-container {
    position: absolute;
    z-index: 7;
    background-color: #fafafa;
    height: 90%;
    width: 100%;
    bottom: 0;
    border-radius: 1vw 1vw 0 0;
    box-shadow: 0 0 0.2vw 0.05vw rgba(0, 0, 0, 0.25);
    animation: fadeIn 0.3s forwards;

    /*selecionar banco*/
    >header {
        height: 15%;
        width: 95%;
        margin-left: 2.5%;
        border-bottom: 0.1vw solid #D1D1D1;
        display: flex;
        align-items: center;
        justify-content: space-evenly;

        >button {
            /* width: 30%;
            height: 2.5vw;
            font-size: 1.5vw;
            font-family: Jost;
            font-weight: 500;
            background-color: transparent;
            box-shadow: 0 0 0.2vw 0.05vw rgba(0, 0, 0, 0.25);
            border: none;
            border-radius: 0.3vw;
            cursor: pointer;
            transition: background-color 0.3s ease, color 0.3s ease, font-weight 0.1s ease; */

            border-radius: 0.4vw;
            box-sizing: border-box;
            color: #FFFFFF;
            cursor: pointer;
            color: #242424;
            font-family: 'Comfortaa', sans-serif;
            font-size: 1.2vw;
            padding: 0.5vw 1vw;
            user-select: none;
            border: none;
            transition: background-color 0.3s ease, color 0.3s ease, font-weight 0.1s ease;
            
        }
    }

    >div {
        width: 95%;
        margin-left: 2.5%;
        margin-top: 2.5%;
        height: 60%;
    }

    >footer {
        height: 20%;
        width: 95%;
        margin-left: 2.5%;
        border-top: 0.1vw solid #D1D1D1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        /*input balance*/
        >div:nth-of-type(1) {
            margin-top: 2.5%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            >div {
                display: flex;
                flex-direction: column;


                label {
                    font-family: Jost;
                    font-size: 1.2vw;
                }


                input {
                    width: 100%;
                    background-color: #fff;
                    border-radius: 0.3vw;
                    height: 1.7vw;
                    border: solid 0.1vw #5A5A5A;
                    font-family: Jost;
                    font-size: 1.1vw;
                    color: #575757;
                    text-indent: 2%;

                    &:focus {
                        outline: none;
                    }
                }
            }
        }

        /*buttons - create and cancel*/
        >div:nth-of-type(2) {
            display: flex;
            justify-content: space-between;
            align-items: center;

            p {
                font-size: 1vw;
                font-family: Jost;
                color: #242424;
                cursor: pointer;

                &:hover {
                    color: #ED4C5C;
                    text-decoration: underline;
                }
            }

            button {
                font-family: Jost;
                border: none;
                background-color: #65C37A;
                font-size: 1.2vw;
                font-weight: 600;
                width: 30%;
                height: 2vw;
                color: #fafafa;
                border-radius: 0.4vw;
                cursor: pointer;

                &:hover {
                    background-color: #4BB763;
                    transition: background-color 0.3s ease;
                }
            }
        }
    }

}

.creating-new-account-overlay {
    position: absolute;
    top: -11.2%;
    height: 100%;
    width: 100%;
    z-index: 6;
    animation: fadeIn 0.3s forwards;
    background-color: rgb(0, 0, 0, 0.4);
}


@media (max-width: 768px) {
    .account-edit-container {
        >footer {
            button {
                background-color: #65C37A;
                font-size: 6vw;
                width: 90%;
                height: 12vw;
                border-radius: 2vw;
            }
        }
    }

    .account-card-rendered-view {
        width: 90%;
        margin-left: 2.5%;
        border-radius: 3vw;
        box-shadow: 0 0 0.8vw 0.2vw rgba(0, 0, 0, 0.25);

        >div:nth-of-type(1) {
            margin-top: 4%;

            /*Imagem e nome*/
            >div {
                display: flex;
                align-items: center;
                width: 50%;
                max-width: 50%;

                img {
                    width: 12vw;
                    border-radius: 50%;
                }

                p {
                    font-size: 5vw;
                    margin-left: 5%;
                    min-width: 42vw;
                    max-width: 42vw;
                }
            }

            >p {
                font-size: 5vw;
                font-weight: 500;
                width: 35%;
            }


        }
    }

    .account-card-in-edit-view {
        height: 60%;

        /*inputs*/
        >div:nth-of-type(1) {

            >div {
                width: 45%;

                label {
                    font-size: 4.2vw;
                    width: 100%;
                }

                input {
                    border-radius: 1vw;
                    height: 6vw;
                    font-size: 4.3vw;
                }
            }
        }

        /*botões*/
        >div:nth-of-type(2) {

            >p:nth-of-type(1) {
                font-size: 4vw;
            }

            >p:nth-of-type(2) {
                font-size: 4vw;
            }
        }
    }


    .create-new-account-container {

        /*selecionar banco*/
        >header {
            display: flex;
            align-items: center;

            >button {
                width: 40%;
                height: 10vw;
                font-size: 6vw;
                background-color: transparent;
                box-shadow: 0 0 0.8vw 0.3vw rgba(0, 0, 0, 0.25);
                border-radius: 2vw;
            }
        }

        >footer {

            /*input balance*/
            >div:nth-of-type(1) {

                >div {
                    label {
                        font-size: 5vw;
                    }

                    input {
                        border-radius: 1vw;
                        height: 6vw;
                        font-size: 4.3vw;
                        width: 95%;
                    }
                }
            }

            /*buttons - create and cancel*/
            >div:nth-of-type(2) {

                p {
                    font-size: 5vw;
                    color: #ED4C5C;
                }

                button {
                    font-size: 5vw;
                    font-weight: 600;
                    width: 40%;
                    margin-right: 2%;
                    height: 8vw;
                    border-radius: 2vw;
                    cursor: pointer;
                }
            }
        }
    }
}