.Section3AboutUs {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 10vw 0px;
    height: 20vw;

    >img:nth-of-type(1) {
        margin-left: 10%;
        width: 55vw;
        display: block;
    }

    >img:nth-of-type(2) {
        display: none;
    }

    div {
        margin-right: 10%;

        h1 {
            font-family: Jost;
            font-size: 2.2vw;
            text-align: left;
            margin-bottom: 4%;
            color: #383227;
        }

        h2 {
            font-family: Jost;
            font-size: 1.2vw;
            font-weight: 400;
            text-align: left;
            color: #383227;
        }

        ul {
            display: flex;
            flex-direction: column;
            gap: 1vw;
            margin-top: 5%;
            list-style: none;
        }

        li {
            display: flex;
            align-items: center;
            font-size: 1.2vw;
            text-align: left;
            font-family: Jost;
            color: #383227;
            font-weight: 500;
        }

        li p {
            margin-left: 4%;
        }

    }
}

/* Tablet or small screens settings */
@media only screen and (max-width: 1000px) {
    .Section3AboutUs {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 30vh;
    
        >img:nth-of-type(1) {
            width: 50vw;
        }
        div {
            height: 33vw;
            
            h1 {
                font-size: 2.42vw;
            }
    
            h2 {
                font-size: 1.6vw;
            }
    
            ul {
                gap: 2.5vw;
                margin-top: 5%;
            }
    
            li {
                font-size: 1.5vw;
            }
    
        }

    }
    .rightSideImages{
        width: 3.2vw;
    }
}

/* Mobile settings */
@media only screen and (max-width: 768px) {
    .Section3AboutUs {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 70vw;

        >img:nth-of-type(1) {
            display: none;
        }

        >img:nth-of-type(2) {
            display: block;
            height: 100%;
            max-width: 50%;
        }


        div {
            height: 50vw;
            margin-right: 0%;
            h1 {
                font-size: 5.5vw;
            }

            h2 {
                font-size: 4vw;
            }

            ul {
                gap: 2.5vw;
            }

            li {
                font-size: 3.5vw;
                text-align: left;
                font-weight: 500;
            }

            li p {
                margin-left: 4%;
            }

        }
        
    }
    .rightSideImages{
        width: 3vw;
    }
}