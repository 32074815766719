.mainContainerFooterLP{
    height: 30vh;
    width: 100%;

    header{
        margin-left: 2.5%;
        width: 95%;
        height: 50%;
        border-bottom: 0.1vw solid #BABABA;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 1vw;
        font-family: Jost;

        p{
            border-left: 0.1vw solid #BABABA;
            height: 1.5vw;
            padding-left: 1.5vw;
            display: flex;
            align-items: center;
        }

        img{
            width: 10vw;
        }

        ul{
            display: flex;
            width: 30vw;
            align-items: center;
            justify-content: space-between;
        }

        ul li{
            list-style-type: none;
        }

        li:hover{
            cursor: pointer;
            text-decoration: underline;
        }
    }

    footer{
        margin-left: 2.5%;
        width: 95%;
        height: 50%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        >div:nth-of-type(1){
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 30vw;
            font-size: 0.9vw;
            font-family: Jost;

            >p:nth-of-type(1){
                cursor: pointer;

            }

        }

        >div:nth-of-type(2){
            width: 10vw;
            display: flex;
            justify-content: space-between;

            img{
                height: 1.5vw;
                cursor: pointer;
            }
        }
    }
}

/* Mobile settings */
@media only screen and (max-width: 768px) {
    .mainContainerFooterLP{
        height: 18vh;
    
        header{
            font-size: 2vw;
    
            img{
                width: 20vw;
            }
    
            ul{
                display: flex;
                width: 30vw;
                align-items: center;
                justify-content: space-between;
            }
    
        }
    
        footer{
    
            >div:nth-of-type(1){
                width: 55vw;
                font-size: 1.8vw;
                font-family: Jost;
            }
    
            >div:nth-of-type(2){
                width: 15vw;
                display: flex;
                justify-content: space-between;
    
                img{
                    height: 3.5vw;
                }
            }
        }
    }
    
}